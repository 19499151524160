<template>
	<v-navigation-drawer v-model="$store.state.menu" width="200"  app color="transparent" class="sidemenu-border left-direction background-side">
		<v-list-item class="mt-7 mb-5">
			<v-list-item-content class="justify-center">
				<v-img src="./../assets/logo_side.png" contain width="110" height="120" ></v-img>
			</v-list-item-content>
		</v-list-item>

		<v-list dense tile flat dark class="right-direction mb-15">
			<div v-for="(modulo, i) in menu" :key="i">
				<v-list-group :data-cy="modulo.cy" v-if="modulo.submenu.length > 0" v-model="modulo.active" v-permission:any="modulo.permissions??'all'">
					<template v-slot:activator >
						<v-list-item-title class="list-item">{{ modulo.title }}</v-list-item-title>
					</template>
					
					<v-list-item v-for="(submodulo, j) in modulo.submenu" :key="j" :to="submodulo.link" :inactive="submodulo.link === null" class="list-item" active-class="active-list-item" v-permission:any="submodulo.permissions??'all'" :data-cy="submodulo.cy">
						<!-- <v-list-item-icon><v-icon>{{ submodulo.icon }}</v-icon></v-list-item-icon> -->
						<v-list-item-title>{{ submodulo.title }}</v-list-item-title>						
					</v-list-item>
				</v-list-group>

				<v-list-item :data-cy="modulo.cy" v-permission:any="modulo.permissions??'all'" :to="modulo.link" v-else :inactive="modulo.link === null" class="list-item" active-class="active-list-item">
					<!-- <v-list-item-icon><v-icon>{{ modulo.icon }}</v-icon></v-list-item-icon> -->
					<v-list-item-title>{{ modulo.title }}</v-list-item-title>
				</v-list-item>
			</div>
		</v-list>
        <!-- <div class="triangle triangle-1"></div>
        <div class="triangle triangle-2"></div>
        <div class="triangle triangle-3"></div>
        <div class="triangle triangle-4"></div> -->
	</v-navigation-drawer>
</template>

<script>
	export default {
		name: 'Sidemenu',
		watch: {
			$route(to) {
				if (to.matched.length >= 1) {
					const parent = to.matched.filter(m => !m.parent)[0];
					const route = this.menu.filter(route => route.name === parent.meta.parent)[0];
					if(route != undefined)
						route.active = true;
				}
			}
		},
		data () {
			return {
				menu: [
					{
						title: 'Dashboard',
						name: 'Dashboard',
						icon: 'mdi-account-hard-hat',
						active: false,
                        cy:"dashboard-side-items",
						submenu: [],
						link: '/dashboard',
                        permissions: 'dashboards.show'
					},	
                    // {
					// 	title: 'Cuentas gastos AA',
					// 	name: 'CGAA',
					// 	icon: 'mdi-account-hard-hat',
					// 	active: false,
					// 	submenu: [],
					// 	link: '/cgaa',
                    //     permissions: 'ca_payable_accounts.show'
					// },	
                    {
						title: 'Supervisión',
						name: 'Supervision',
						icon: 'mdi-account-hard-hat',
						active: false,
                        cy:"supervision-side-items",
						submenu: [],
						link: '/supervision',
                        permissions: 'supervisions.index|supervisions.show'
					},	
					{
						title: 'Inventario',
						name: 'Inventory',
						icon: 'mdi-cart',
                        cy:"inventory-side-items",
						active: false,
                        permissions: 'consumables.show|equipments.show|entryOrders.show',
						submenu: [
							// { title: 'Consumible', icon: 'mdi-star-face', name: 'Consumable', link: '/consumables', permissions: 'consumables.show', cy:"consumables-link"},
							{ title: 'Equipos', icon: 'mdi-star-face', name: 'Equipments', link: '/equipments', permissions: 'equipments.show', cy:"equipments-link"},
							{ title: 'Ordenes de entrada', icon: 'mdi-star-face', name: 'EntryOrders', link: '/entry-orders', permissions: 'entryOrders.show', cy:"entryOrders-link"},
						]
					},

                    {
						title: 'Compras',
						name: 'Purchases',
						icon: 'mdi-cart',
						active: false,
                        cy:"purchases-side-items",
                        permissions: 'suppliers.show|purchaseOrders.show|requisitions.show',
						submenu: [
							{ title: 'Proveedores', icon: 'mdi-star-face', name: 'Suppliers', link: '/suppliers', permissions: 'suppliers.show', cy:"suppliers-link"},
							{ title: 'Ordenes de compra', icon: 'mdi-star-face', name: 'PuchaseOrders', link: '/purchase-orders', permissions: 'purchaseOrders.show', cy:"purchaseOrders-link"},
							{ title: 'Requisiciones', icon: 'mdi-star-face', name: 'Requisitions', link: '/requisitions', permissions: 'requisitions.show', cy:"requisitions-link"},
						]
					},

                    {
						title: 'Ventas',
						name: 'Sales',
						icon: 'mdi-cart',
						active: false,
                        cy:"sales-side-items",
                        permissions: 'prospects.show|fee.show|clients.show|clientContacts.show|services.show|quotations.show|supplies.show',
						submenu: [
							{ title: 'Prospectos', icon: 'mdi-star-face', name: 'Prospects', link: '/prospects', permissions: 'prospects.show', cy:"prospects-link"},
							{ title: 'Cotizaciones', icon: 'mdi-star-face', name: 'Fees', link: '/quotations', permissions: 'fee.show', cy:"fee-link"},
							{ title: 'Clientes', icon: 'mdi-star-face', name: 'Clients', link: '/clients', permissions: 'clients.show', cy:"clients-link"},
                            { title: 'Tarifas', icon: 'mdi-star-face', name: 'Quotations', link: '/fees', permissions: 'quotations.show', cy:"quotations-link"},
                            { title: 'Contactos', icon: 'mdi-star-face', name: 'Schedules', link: '/schedules', permissions: 'clientContacts.show', cy:"clientContacts-link"},
                            { title: 'Insumos', icon: 'mdi-star-face', name: 'Supplies', link: '/supplies', permissions: 'supplies.show', cy:"supplies-link"},
                            { title: 'Servicios', icon: 'mdi-star-face', name: 'Services', link: '/services', permissions: 'services.show', cy:"services-link"},
						]
					},
                    {
						title: 'Operaciones',
						name: 'Sales',
						icon: 'mdi-cart',
						active: false,
                        permissions: 'saleOrders.show|billings.show',
                        cy:"operations-side-items",
						submenu: [
							{ title: 'Remisiones', icon: 'mdi-star-face', name: 'SaleOrders', link: '/sale-orders', permissions: 'saleOrders.show',  cy:"saleOrders-link"},
                            { title: 'Facturaciones', icon: 'mdi-star-face', name: 'Billing', link: '/billing', permissions: 'billings.show',  cy:"billings-link"},
						]
					},
                    
					
                    {
						title: 'Catálogos',
						name: 'Catalog',
						icon: 'mdi-cart',
						active: false,
                        cy:"catalog-side-items",
                        permissions: 'warehouses.show|banks.show|departments.show|packagings.show|types.show|units.show',
						submenu: [
							{ title: 'Almacenes', icon: 'mdi-star-face', name: 'Warehouses', link: '/catalogs/warehouses', permissions: 'warehouses.show', cy:"warehouses-link"},
                            { title: 'Bancos', icon: 'mdi-star-face', name: 'Warehouses', link: '/catalogs/banks', permissions: 'banks.show', cy:"banks-link"},
                            { title: 'Departamentos', icon: 'mdi-star-face', name: 'Departments', link: '/catalogs/departments', permissions: 'departments.show', cy:"departments-link"},
                            { title: 'Embalajes', icon: 'mdi-star-face', name: 'Packagings', link: '/catalogs/packagings', permissions: 'packagings.show', cy:"packagings-link"},
                            { title: 'Tipos', icon: 'mdi-star-face', name: 'Types', link: '/catalogs/types', permissions: 'types.show',cy:"types-link"},
                            { title: 'Unidades', icon: 'mdi-star-face', name: 'Units', link: '/catalogs/units', permissions: 'units.show', cy:"units-link"},
						]
					},
                    {
						title: 'Permisos',
						name: 'Permission',
						icon: 'mdi-cart',
						active: false,
                        permissions: 'users.show|roles.show',
                        cy:"permission-side-items",
						submenu: [
							{ title: 'Usuarios', icon: 'mdi-star-face', name: 'User', link: '/users', permissions: 'users.show', cy:"users-link"},
							{ title: 'Roles', icon: 'mdi-star-face', name: 'Role', link: '/roles', permissions: 'roles.show', cy:"roles-link"},
						]
					},
					
					
				],
				right: null,
			}
		},
       
		methods: {
			
		}
	};
</script>
<style lang="scss">
	.v-list-group__items .v-list-item {
		padding-left: 30px;
	}

    .list-item{
        color: var(--v-sidebarText-base) !important;
    }

    .active-list-item{
       
        background-color: var(--v-primary-base);
        color: var(--v-sidebarTextActive-base) !important;
    }

    .v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child){
        margin-left: unset !important;
    }
    .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon{
        min-width: unset !important;
    }

	.v-list-group__items .v-list-item.border-orange {
		padding-left: 24px !important;
	}

	.v-list-item.border-orange{
		padding-left: 10px !important;
	}

	.v-list--dense .v-list-item .v-list-item__icon{
		margin-top: 7px;
		margin-bottom: 7px;
	}

    .v-list .v-list-item--active .v-icon{
        color: var(--v-sidebarText-base) !important;
    }

    .sidemenu-border{
        -webkit-border-radius: 0px 70px 70px 0px;
        -moz-border-radius: 0px 70px 70px 0px;
        border-radius: 0px 70px 70px 0px;
    }

    .right-direction{
        direction: ltr !important;
    }

    .left-direction{
        direction: rtl !important;
    }

    .background-side{
        background-image: url('~@/assets/side_1.png'); //WORKED!
        background-size: cover;
    }


    .triangle{
        width: 100%;
        height: 350px;
        background: #555;
        position: absolute;
        bottom: 0px;
        left: 200px;
        z-index: -10;
		
        &::after{
            content: "";
            width: 400%;
            height: 400px;
            position: absolute;
            left: -250%;
            z-index: -1;
            -webkit-clip-path:  polygon(50% 0%, 0% 100%, 100% 100%);
            clip-path:  polygon(50% 0%, 0% 100%, 100% 100%);

			opacity: 0;
			animation-name: triangeMoveInbuttom;
			animation-duration: 1s;
			animation-fill-mode: forwards;  
			animation-timing-function: ease-out; /*como va manejar el tiempo de la animacion (esase-out: se acelera al final)*/
    
        }

        &-1{
            &::after{
                background: var(--v-sidebarDetail1-base);
                bottom: -40%;
				animation-delay: 1.2s;
            }
			
        }

        &-2{
            &::after{
                background: var(--v-sidebarDetail2-base);;
                bottom: -55%;
				animation-delay: 0.8s;
            }
        }

        &-3{
            &::after{
                background: var(--v-sidebarDetail3-base);
                bottom: -70%;
				animation-delay: 0.4s;
            }
        }


        &-4{
            &::after{
                background: var(--v-sidebarDetail4-base);;
                bottom: -85%;
            }
        }

    }



</style>