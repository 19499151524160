<template>
	<contentCardIndex >
        <template slot="cardContent">
            <v-row align="center">
                <v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                    
                    <alternativeTextField2
                        @onChanged="(v) => {trySearch(v);}" 
                        :valueInput="tableProps.search"
                        :rule="[]"
                        :placeholder="'Buscar'"
                        :label="''"
                        
                        :prependInnerIcon="'mdi-magnify'"
                        class="pick-3"
                        data-cy="search-input"
                    ></alternativeTextField2>
                </v-col>
                <v-spacer></v-spacer>
                <v-col  v-permission="'requisitions.store'" cols="12" md="3" lg="3" xl="2" class="mx-0 ">
                    <v-row justify="end">
                        <primaryButton data-cy="create-btn" :props="createBtnData"></primaryButton>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mt-md-0">
                <v-col class="mx-0 px-0">
                    <datatable data-cy="index-table" :props="tableProps" @methodHandler="method_handler" >
                        <template v-slot:[`item.sku`]="{ item }">
                            <span v-if="item.status == 'Pendiente'"><v-icon small  color="red" class="requisition-status-light">mdi-circle</v-icon> {{item.sku}}</span>
                            <span v-else ><v-icon small  color="" class="requisition-status-light">mdi-circle-outline</v-icon> <span class="" >{{item.sku}}</span></span>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip data-cy="index-table-status-item" :color="getStatusColor(item.status)"><span :class="`${getStatusTextColor(item.status)}--text`">{{item.status}}</span></v-chip>
                        </template> 
                        <template v-slot:[`item.delivery`]="{ item }">
                            <v-chip :color="getStatusColor(item.delivery)"><span :class="`${getStatusTextColor(item.delivery)}--text`">{{item.delivery}}</span></v-chip>
                        </template> 
                    </datatable>
                </v-col>
            </v-row>
            <modal data-cy="index-modal" :props="modalProps" @modalResponse="method_handler">
                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end" data-cy="index-modal-actions">
                        <v-col cols="12" sm="4">
                            <secondaryButton
                            data-cy="cancel-modal-btn"
                            :props="cancelModal"
                            ></secondaryButton>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <primaryButton
                            data-cy="accept-modal-btn"
                            :props="acceptModal"
                            ></primaryButton>
                        </v-col>
                    </v-row>
                </template> 
            </modal>
        </template>
    </contentCardIndex>
</template>
<script>
import Utils from '../../../helpers/Utils'
// import Utils from "../../../helpers/Utils";
export default {
    data(){
		return {
           
            
			search: '',
            selectedID: '',
            
			actions: [
				
               
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'requisitions.edit',
                    dataCy: "edit-link"
				},

                {
					name: 'Órden de compra',
					icon: {
						color: 'secondary',
						icon: 'mdi-file-document-outline'
					},
					disabled: true,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'requisitions.edit',
                    dataCy: "edit-link"
				},
               
				// {
				// 	name: 'Borrar',
				// 	icon: {
				// 		color: 'secondary',
				// 		icon: 'mdi-delete-outline'
				// 	},
				// 	disabled: false,
				// 	type: 'method',   //  method, external, redirect
				// 	action: 'deleteItemModal',
                //     permissions: 'requisitions.delete',
                //     dataCy: "delete-link"
				// },
			],
			tableProps: {
				headers: [
                     {
						text: 'Folio',
						align: 'left break-words',
						value: 'sku',
                        width: "10.5%",
						class: 'table-b-border black--text'
					},
					{
						text: 'Solicitante',
						align: 'left',
						value: 'requester',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Fecha',
						align: 'left',
						value: 'date',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'prioridad',
						align: 'left',
						value: 'priority',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Estatus',
						align: 'left',
						value: 'status',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Entrega',
						align: 'left',
						value: 'delivery',
						
						class: 'table-b-border black--text'
					},
                    
                   
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false,
                sortBy: "sku",
                sortDesc: true
			},
			
			
            createBtnData: {
                text: "Nueva Requisición",
                icon: "mdi-plus",
				to: "RequisitionsCreate",
				block: false,
                click: ()=>{}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                cy:"cancel-modal-btn",
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                cy:"accept-modal-btn",
                click: ()=>{this.delete()}
            },
		}
	},

	mounted(){
		this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.createBtnData.block = true;
                
            }
            else{
                this.createBtnData.block = false;
                
            }
        },
      
        trySearch(val){
            this.tableProps.search = val;
        },
		index() {

            // const data = [
            //     {
            //         sku: "req000001",
            //         requester: "Yuz ventura",
            //         date: "2024-01-03",
            //         priority: "urgente",
            //         status: "pendiente",
            //         delivery: "pendiente"
            //     },
            //     {
            //         sku: "req000002",
            //         requester: "Yuz ventura",
            //         date: "2024-01-03",
            //         priority: "2024-04-12",
            //         status: "parcial",
            //         delivery: "flujo"
            //     },
            //     {
            //         sku: "req000003",
            //         requester: "Yuz ventura",
            //         date: "2024-01-03",
            //         priority: "2024-04-12",
            //         status: "aprobada",
            //         delivery: "surtida"
            //     },
            //     {
            //         sku: "req000004",
            //         requester: "Yuz ventura",
            //         date: "2024-01-03",
            //         priority: "2024-04-12",
            //         status: "aprobada",
            //         delivery: "parcial"
            //     },
            //     {
            //         sku: "req000005",
            //         requester: "Yuz ventura",
            //         date: "2024-01-03",
            //         priority: "urgente",
            //         status: "rechazada",
            //         delivery: "N/A"
            //     },
            //     {
            //         sku: "req000006",
            //         requester: "Yuz ventura",
            //         date: "2024-01-03",
            //         priority: "2024-04-12",
            //         status: "parcial",
            //         delivery: "flujo"
            //     },
            // ];
            
			this.tableProps.loading = true;
           
			// call api
			this.$api.requisition.list()
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.map((x,i) => {
                        x['actions'] = JSON.parse(JSON.stringify(this.actions))

                        // x.status = "Aprobada";

                        if(x?.status.toLowerCase() == "aprobada" || x.status == "Rechazada"){
                            x['actions'] = x['actions'].toSpliced(0, 0, 
                                {
                                    name: 'Detalles',
                                    icon: {
                                        color: 'secondary ',
                                        icon: 'mdi-information-outline'
                                    },
                                    disabled: false,
                                    type: 'redirect',   //  method, external, redirect
                                    action: '',
                                    permissions: 'requisitions.show',
                                    dataCy: "details-link"
                                },
                            );
                            x.actions[1].disabled = true;
                            x.actions[0].action = { name: 'RequisitionsDetails', params: { id: x.sku } }
                        }else{
                            x['actions'] = x['actions'].toSpliced(0, 0, 
                                {
                                    name: 'Revisar',
                                    icon: {
                                        color: 'secondary ',
                                        icon: 'mdi-list-status'
                                    },
                                    disabled: false,
                                    type: 'redirect',   //  method, external, redirect
                                    action: '',
                                    permissions: 'requisitions.show',
                                    dataCy: "review-link"
                                },
                            );
                            x.actions[0].action = { name: 'RequisitionsReview', params: { id: x.sku } }
                        }

                        if(x.status == "Aprobada"){
                            x.actions[2].disabled = false
                        
                        }
                        
                        
                        // x.actions[0].action = { name: '', params: { slug: x.slug } }
                        x.actions[1].action = { name: 'RequisitionsEdit', params: { id: x.sku } }
                        x.actions[2].action = { name: 'PurchaseOrdersCreate', params: { reqId: x.sku } }
                        

                        //poner en rojo el circle
                        //checar que status va ser el rojo
                        // x.status = "Pendiente";

                        x['parameters'] = { id: x.sku, name: x.sku, index: i};
                        x.date = Utils.globalDateFormat(x.date);
                        x.priority = x.priority == 1 ? "Urgente" : x.date;


                        //quitar
                        // x.delivery = "Pendiente";
                        
                        return x;
                    });
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

		deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.requisition.delete(this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},

        getStatusColor(status){
            switch (status) {
                case "Pendiente":
                    return "#EA8941";
                case "Parcial":
                    return "#FBF062";
                case "Aprobada":
                case "Surtida":
                    return "#65C14E";
                case "Rechazada":
                    return "#C93029";
                case "Flujo":
                    return "#47A1DB";
               
                    
            
                default:
                    return "#BBBBBB"
            }
        },
        getStatusTextColor(status){
            switch (status) {
                case "Parcial":
                    return "black";
                    
            
                default:
                    return "white"
            }
        }

    }
}
</script>

<style lang="scss">
    .requisition-status-light{
        width: 20px;
        padding-bottom: 2px;
        vertical-align: top !important;
    }
</style>