<template>
    <div>
       <v-tooltip top v-for="(action, i) of item.actions" :key="i">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" :data-cy="action.dataCy" x-small class="mx-0" icon @click="methodHandler(action.action, item.parameters)" :disabled="action.disabled"
                >
                    <v-icon  v-if="action.action != 'openComments'" :color="action.icon.color" >{{ action.icon.icon }}</v-icon>

                    <v-menu
                    v-if="action.action == 'openComments'"
                    v-model="item.menu"
                    
                    left
                    offset-x
                    :nudge-top="nudgeTop == null ? 10 : nudgeTop"
                    :nudge-left="nudgeLeft == null ? 12 : nudgeLeft"
                    :close-on-content-click="false"
                    content-class="menuclasss"
                    max-width="500"
                    >
                    <template v-slot:activator="{ on, attrs }">
                            <!-- <div class="triangle-left">
                                <div class="inner-triangle"></div>
                            </div> -->
                            <v-btn v-bind="attrs" :data-cy="action.dataCy" x-small class="mx-0" icon  :disabled="action.disabled" >
                                <v-icon :color="action.icon.color" @click="(a)=>{on.click(a); info(on)}">{{ action.icon.icon }}</v-icon>
                            </v-btn>
                            <div :class="`${item.menu ? 'requisitionsTableActions-testclass d-none d-md-inline-flex' : 'requisitionsTableActions-notshow'}  ` ">
                            </div>
                            <!-- <span >a</span>     -->
                    
                    </template>

                    <!-- window container -->
                    <div class="requisitionsTableActions-comments-container" :key="messagesWindowKey">
                        <p class="py-2 my-0 font-weight-bold">
                            Comentarios sobre el producto {{item.name}}
                        </p>
                        
                        <div class="requisitionsTableActions-comments-chat" ref="listChat" v-if="openComments(item)"
                        
                        >
                            <div v-for="(message, index) in item.localMessages" :key="index">
                                <div :class="`my-3 mx-2 ${message.position == 'right' ? 'text-right' : 'text-left'}`">
                                    <p class="pb-0 mb-0 requisitionsTableActions-comments-comment"  data-cy="comments-chat-text">{{message.comments}}</p>
                                    <p class="pb-0 mb-0 requisitionsTableActions-comments-user">{{message.user}} ({{message.date}})</p>
                                </div>
                            </div>
                        </div>

                        <v-row v-if="!show">
                            <v-col class="py-2 mt-3">
                                <textAreaForm 
                                    @valueChange="(v) => {comment = v;}" 
                                    :valueInput="comment" 
                                    :rule="[]" 
                                    label="Comentarios"
                                    :errorMessages="''"
                                    data-cy="comments-item-input"
                                    class="my-3"
                                ></textAreaForm>
                            </v-col>
                            <v-col class="py-0 my-0 mb-5" cols="12" md="3" align-self="end">
                                <div @click="() => {addComments(item)}">
                                    <primary-button data-cy="closeComments-btn" :props="closeCommentsBtnData" class="" ></primary-button> 

                                </div>
                            </v-col>
                        </v-row>
                        
                    </div>
                </v-menu>
                
                </v-btn>

                
            </template>
            <span>{{ action.name }}</span>
        </v-tooltip>

    </div>
</template>

<script>
import Utils from '../../../helpers/Utils'
    export default {
    
        name: 'datatable',
        props: ['itemInput', 'parameters', 'edit', 'nudgeTop', 'nudgeLeft', 'show'],
        computed:{
            item: {
                get: function() {
                    console.log(this.itemInput, "itemInput !!!!!")
                    return this.itemInput
                },
                set: function(value) {
                    if(value == null)
                        this.$emit('valueChange', "")
                    else
                        this.$emit('valueChange',value)
                }
            },

        },
        data(){
            return {
                messagesWindowKey: 2023,
                comment: "",
                closeCommentsBtnData: {
                    text: "Aceptar",
                    icon: "",	
                    click: ()=>{},
                    block:false,
                    loading: false,
                },
            }
        },
        methods: {
            methodHandler(methodName, parameters){
                console.log(methodName, parameters, " es methodnhadeesto")
                this.$emit('methodHandler', {
                    methodName: methodName, 
                    parameters: parameters
                });
            },
            info(data){
                console.log(data, "info")
            },
            closeComments(){
                this.menu = false;
            },
            tobottom () {
                try {
                    this.$refs.listChat[0].scrollTop = this.$refs.listChat[0].lastElementChild.offsetTop;
                } catch (error) {
                    console.log(error);
                }
            },
            openComments(){
                try {
                    setTimeout(() => {
                        this.tobottom();
                        
                    }, 100);
                } catch (error) {
                    console.log(error);
                }

                return true;
            },

            addComments(item){
                if(this.comment == "")
                    return;
                // console.log(item, "add cooment")
                // this.values.localMessages = 
                item.comments = JSON.parse(JSON.stringify(this.comment));
                if(this.edit){
                    let edited = item.localMessages.find((x) => x.isNew == true);
                    if(edited != undefined){
                        edited.comments = item.comments
                    }else{
                        item.localMessages.push({
                            comments: item.comments,
                            date:  Utils.getNowDate(),
                            position: "right",
                            user: this.$store.state.loggedUsername,
                            isNew:true,
                        })
                    }
                
                }else{
                    item.localMessages = [
                        {
                            comments: item.comments,
                            date:  Utils.getNowDate(),
                            position: "right",
                            user: this.$store.state.loggedUsername,
                            isNew: true,
                        }
                    ];
                }

                this.$emit('commentChange',this.comment);

                this.comment = "";
                this.messagesWindowKey = this.messagesWindowKey + 1;
                item.menu = false;
                console.log(item, "completo")
            },
            
        }
    }
</script>

<style lang="scss">

     .requisitionsTableActions{
        &-information{
            padding-top: 18px;
            line-height: 15px !important;

            &-2{
                padding-top: 8px;
                line-height: 15px !important;
            }
        }



        &-comments-container{
            padding: 15px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #F7F7F7;
            min-height: 200px;
            min-width: 31em;
        }


        &-comments-chat{
            max-height: 300px;
            overflow-y: scroll;
        }

        &-comments-comment{
            font-weight: 700;
            white-space: pre-wrap;
            overflow-wrap: break-word;
        }
        &-comments-user{
            font-size: 0.75rem;
        }
        &-notshow{
            display: none !important;
        }
        &-testclass{
            display: inline-block;
            transform: translateY(6px);
        }
    }
    .menuclasss{
        box-shadow: none !important;
        border: solid 1px black;
    }


    .triangle-left {
        width: 0;
        height: 0;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
        border-left: 11px solid black;
        position: relative;
        display: inline-block;
        // transform: translateY(8px);
        transform: translate(-6px, 1px);
        z-index: 10;
    }

    .triangle-left:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #F7F7F7;
        position: absolute;
        top: -10px;
        left: -11px;
        z-index: 10;
    }
</style>