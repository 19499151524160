
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class RequisitionService extends BaseApi {


    constructor(){
        super();
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/requisitions`)
        .catch(this.handleErrorResponse);
    }

    async get(sku)
    {
        return this.http.get(`${this.baseUrl}/requisitions/${sku}/edit`)
        .catch(this.handleErrorResponse);
    }

    async show(sku)
    {
        return this.http.get(`${this.baseUrl}/requisitions/${sku}/show`)
        .catch(this.handleErrorResponse);
    }


    async store(data)
    {
        return this.http.post(`${this.baseUrl}/requisitions/store`, data)
        .catch(this.handleErrorResponse);
    }

    async update(sku, data)
    {
        return this.http.post(`${this.baseUrl}/requisitions/${sku}/update`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(sku)
    {
        return this.http.delete(`${this.baseUrl}/requisitions/${sku}/delete`)
        .catch(this.handleErrorResponse);
    }


    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/getRequisitions`)
        .catch(this.handleErrorResponse);
    }

    
    async getDetails(sku)
    {
        return this.http.get(`${this.baseUrl}/getRequisition/${sku}`)
        .catch(this.handleErrorResponse);
    }

    async showRevision(sku)
    {
        return this.http.get(`${this.baseUrl}/requisitions/${sku}/request`)
        .catch(this.handleErrorResponse);
    }


    async revisionUpdate(sku, data)
    {
        return this.http.put(`${this.baseUrl}/requisitions/${sku}/requestAction`, data)
        .catch(this.handleErrorResponse);
    }


    async toPurchaseOrder(sku)
    {
        return this.http.get(`${this.baseUrl}/requisitions/${sku}/toPurchaseOrder`)
        .catch(this.handleErrorResponse);
    }

}