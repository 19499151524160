import Home from '../views/Home.vue';
//import testMiddle from "../middleware/testMiddle";
import AuthMiddleware from '../middleware/AuthMiddleware';
import VueGateMiddleware from '../middleware/VueGateMiddleware';

const initialRoutes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            auth: false,
            title: 'Grupo Merca',
            middlewares: {AuthMiddleware}
        }
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            auth: false,
            title: 'Grupo Merca',
            middlewares: {AuthMiddleware}
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../layouts/Login.vue'),
        meta: {
            auth: false,
            title: 'Grupo Merca',
            middlewares: {AuthMiddleware}
        }
  },
];

const dashboardRoutes = [
    /*
        ! DASHBOARD
    */
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Dashboard',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'dashboards.show'
        }
    },
];

const supervisionRoutes = [
    /*
        ! SUPERVISION
    */
    {
        path: '/supervision',
        name: 'Supervision',
        component: () => import(/* webpackChunkName: "about" */ '../views/supervision/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Supervisión',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'supervisions.index'
        }
    },
];

const equipmentRoutes = [
    /*
        ! CONSUMABLES
    */
    // {
    //     path: '/consumables',
    //     name: 'Consumables',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/inventory/consumables/Index.vue'),
    //     meta: {
    //         parent:'',
    //         auth: true,
    //         title: 'Inventario - Consumibles',
    //         middlewares: {AuthMiddleware, VueGateMiddleware},
    //         permissions: 'consumables.show'
    //     }
    // },
    // {
    //     path: '/consumables/new',
    //     name: 'ConsumablesCreate',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/inventory/consumables/Create.vue'),
    //     meta: {
    //         parent:'',
    //         auth: true,
    //         title: 'Inventario - Nuevo Consumible',
    //         middlewares: {AuthMiddleware, VueGateMiddleware},
    //         permissions: 'consumables.store'
    //     }
    // },
    // {
    //     path: '/consumables/:id/edit',
    //     name: 'ConsumablesEdit',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/inventory/consumables/Edit.vue'),
    //     meta: {
    //         parent:'',
    //         auth: true,
    //         title: 'Inventario - Editar Consumible',
    //         middlewares: {AuthMiddleware, VueGateMiddleware},
    //         permissions: 'consumables.edit'
    //     }
    // },
    // {
    //     path: '/consumables/:id/details',
    //     name: 'ConsumablesDetails',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/inventory/consumables/Details.vue'),
    //     meta: {
    //         parent:'',
    //         auth: true,
    //         title: 'Inventario - Detalles del Consumible',
    //         middlewares: {AuthMiddleware, VueGateMiddleware},
    //         permissions: 'consumables.show'
    //     }
    // },
    // {
    //     path: '/consumables/:id/output/new',
    //     name: 'ConsumablesCreateOutput',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/inventory/consumables/OutputCreate.vue'),
    //     meta: {
    //         parent:'',
    //         auth: true,
    //         title: 'Inventario - Salida del consumible',
    //         middlewares: {AuthMiddleware, VueGateMiddleware},
    //         permissions: 'consumables.store'
    //     }
    // },

    /*
        ! EQUIPMENTS
    */
    {
        path: '/equipments',
        name: 'Equipments',
        component: () => import(/* webpackChunkName: "about" */ '../views/inventory/equipments/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Inventario - Equipos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'equipments.show'
        }
    },
    {
        path: '/equipments/new',
        name: 'EquipmentsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/inventory/equipments/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Inventario - Nuevo Equipo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'equipments.store'
        }
    },
    {
        path: '/equipments/:id/edit',
        name: 'EquipmentsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/inventory/equipments/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Inventario - Editar Equipo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'equipments.edit'
        }
    },
    {
        path: '/equipments/:id/details',
        name: 'EquipmentsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/inventory/equipments/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Inventario - Detalles del Equipo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'equipments.show'
        }
    },
    {
        path: '/equipments/:id/output/new',
        name: 'EquipmentsCreateOutput',
        component: () => import(/* webpackChunkName: "about" */ '../views/inventory/equipments/OutputCreate.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Inventario - Salida del equipo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'equipments.store'
        }
    },

    /*
        ! ENTRY ORDERS
    */
    {
        path: '/entry-orders',
        name: 'EntryOrders',
        component: () => import(/* webpackChunkName: "about" */ '../views/inventory/entryOrders/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Inventario - Ordenes de entrada',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'entryOrders.show'
        }
    },
    {
        path: '/entry-orders/new',
        name: 'EntryOrdersCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/inventory/entryOrders/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Inventario - Registrar Órden de entrada',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'entryOrders.store'
        }
    },
    {
        path: '/entry-orders/:id/details',
        name: 'EntryOrdersDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/inventory/entryOrders/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Inventario - Detalles de Órden de entrada',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'entryOrders.show'
        }
    },
];

const purchasesRoutes = [
    /*
        ! SUPPLIERS
    */
    {
        path: '/suppliers',
        name: 'Suppliers',
        component: () => import(/* webpackChunkName: "about" */ '../views/purchases/suppliers/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Proveedores',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'suppliers.show'
        }
    },
    {
        path: '/suppliers/new',
        name: 'SuppliersCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/purchases/suppliers/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Nuevo Proveedor',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'suppliers.store'
        }
    },
    {
        path: '/suppliers/:id/edit',
        name: 'SuppliersEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/purchases/suppliers/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Editar Proveedor',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'suppliers.edit'
        }
    },
    {
        path: '/suppliers/:id/details',
        name: 'SuppliersDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/purchases/suppliers/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Detalles del Proveedor',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'suppliers.show'
        }
    },

    /*
        ! PURCHASE ORDERS
    */
    {
        path: '/purchase-orders',
        name: 'PurchaseOrders',
        component: () => import(/* webpackChunkName: "about" */ '../views/purchases/purchaseOrders/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Ordenes de compra',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'purchaseOrders.show'
        }
    },
    {
    path: '/purchase-orders/new/:reqId?',
    name: 'PurchaseOrdersCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/purchases/purchaseOrders/Create.vue'),
    meta: {
        parent:'',
        auth: true,
        title: 'Compras - Registrar Orden de compra',
        middlewares: {AuthMiddleware, VueGateMiddleware},
        permissions: 'purchaseOrders.store'
    }
    },
    {
        path: '/purchase-orders/:id/edit',
        name: 'PurchaseOrdersEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/purchases/purchaseOrders/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Editar Orden de compra',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'purchaseOrders.edit'
        }
    },
    {
        path: '/purchase-orders/:id/details',
        name: 'PurchaseOrdersDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/purchases/purchaseOrders/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Detalles de Orden de compra',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'purchaseOrders.show'
        }
    },

    /*
        ! REQUISITIONS
    */
    {
        path: '/requisitions',
        name: 'Requisitions',
        component: () => import(/* webpackChunkName: "about" */ '../views/purchases/requisitions/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Requisiciones',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'requisitions.show'
        }
    },
    {
        path: '/requisitions/new',
        name: 'RequisitionsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/purchases/requisitions/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Registrar Requisiciones',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'requisitions.store'
        }
    },
    {
        path: '/requisitions/:id/edit',
        name: 'RequisitionsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/purchases/requisitions/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Editar Requisición',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'requisitions.edit'
        }
    },
    {
        path: '/requisitions/:id/details',
        name: 'RequisitionsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/purchases/requisitions/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Detalles de Requisición',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'requisitions.show'
        }
    },
    {
        path: '/requisitions/:id/review',
        name: 'RequisitionsReview',
        component: () => import(/* webpackChunkName: "about" */ '../views/purchases/requisitions/Review.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Revisar Requisición',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'requisitions.edit'
        }
    },
];

const salesRoutes = [
    /*
        ! PROSPECT
    */
    {
        path: '/prospects',
        name: 'Prospects',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/prospects/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Prospectos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'prospects.show'
        }
    },
    {
        path: '/prospects/new',
        name: 'ProspectsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/prospects/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Registrar Prospecto',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'prospects.store'
        }
    },
    {
        path: '/prospects/:id/edit',
        name: 'ProspectsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/prospects/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Editar Prospecto',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'prospects.edit'
        }
    },
    {
        path: '/prospects/:id/reminders',
        name: 'Reminders',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/prospects/reminders/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Recordatorios',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'prospects.show'
        }
    },
    {
        path: '/prospects/:id/reminders/new',
        name: 'RemindersCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/prospects/reminders/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Nuevo recordatorio',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'prospects.store'
        }
    },
    {
        path: '/prospects/:id/reminders/:reminder_id/edit',
        name: 'RemindersEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/prospects/reminders/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Editar recordatorio',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'prospects.edit'
        }
    },
    {
        path: '/prospects/:id/reminders/:reminder_id/details',
        name: 'RemindersDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/prospects/reminders/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Detalles del recordatorio',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'prospects.show'
        }
    },

    /*
        ! FEE (QUOTATION?)
    */
    {
        path: '/quotations',
        name: 'Fees',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/fees/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Cotizaciones',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'fee.show'
        }
    },
    {
        path: '/quotations/new',
        name: 'FeesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/fees/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Nueva Cotización',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'fee.store'
        }
    },
    {
        path: '/quotations/:id/edit',
        name: 'FeesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/fees/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Editar Cotización',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'fee.edit'
        }
    },
    {
        path: '/quotations/:id/details',
        name: 'FeesDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/fees/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Detalles de Cotización',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'fee.show'
        }
    },


    /*
        ! CLIENTS
    */
    {
        path: '/clients',
        name: 'Clients',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/clients/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Clientes',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'clients.show'
        }
    },
    {
        path: '/clients/new',
        name: 'ClientsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/clients/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Nuevo Cliente',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'clients.store'
        }
    },
    {
        path: '/clients/:id/edit',
        name: 'ClientsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/clients/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Editar Cliente',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'clients.edit'
        }
    },
    {
        path: '/clients/:id/details',
        name: 'ClientsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/clients/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Detalles del Cliente',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'clients.show'
        }
    },

    /*
        ! SCHEDULE
    */
    {
        path: '/schedules',
        name: 'Schedules',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/schedules/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Contactos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'clientContacts.show'
        }
    },
    {
        path: '/schedules/new',
        name: 'SchedulesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/schedules/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Nuevo Contacto',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'clientContacts.store'
        }
    },
    {
        path: '/schedules/:id/edit',
        name: 'SchedulesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/schedules/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Editar Contacto',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'clientContacts.edit'
        }
    },

    /*
        ! SUPPLIES
    */
    {
        path: '/supplies',
        name: 'Supplies',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/supplies/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Insumos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'supplies.show'
        }
    },
    {
        path: '/supplies/new',
        name: 'SuppliesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/supplies/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Insumos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'supplies.store'
        }
    },
    {
        path: '/supplies/:id/edit',
        name: 'SuppliesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/supplies/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Insumos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'supplies.edit'
        }
    },


    /*
        ! SERVICES
    */
    {
        path: '/services',
        name: 'Services',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/services/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Servicios',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'services.show'
        }
    },
    {
        path: '/services/new',
        name: 'ServicesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/services/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Nuevo Servicio',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'services.store'
        }
    },
    {
        path: '/services/:id/edit',
        name: 'ServicesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/services/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Editar Servicio',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'services.edit'
        }
    },

    /*
        ! Quotation (FEE)
    */
    {
        path: '/fees',
        name: 'Quotations',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/quotations/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Tarifas',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'quotations.show'
        }
    },
    {
        path: '/fees/new',
        name: 'QuotationsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/quotations/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Nueva Tarifa',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'quotations.store'
        }
    },
    {
        path: '/fees/:id/edit',
        name: 'QuotationsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/quotations/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Editar Tarifa',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'quotations.edit'
        }
    },
    {
        path: '/fees/:id/details',
        name: 'QuotationsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/quotations/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Detalles Tarifa',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'quotations.show'
        }
    },
];

const operationRoutes = [
    /*
        ! Sale Orders
    */
    {
        path: '/sale-orders',
        name: 'SaleOrders',
        component: () => import(/* webpackChunkName: "about" */ '../views/operations/saleOrders/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Operaciones - Remisiones',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'saleOrders.show'
        }
    },
    {
        path: '/sale-orders/new',
        name: 'SaleOrdersCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/operations/saleOrders/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Operaciones - Nueva Remisión',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'saleOrders.store'
        }
    },
    {
        path: '/sale-orders/:id/edit',
        name: 'SaleOrdersEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/operations/saleOrders/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Operaciones - Editar Remisión',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'saleOrders.edit'
        }
    },
    {
        path: '/sale-orders/:id/details',
        name: 'SaleOrdersDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/operations/saleOrders/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Operaciones - Detalles de Remisión',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'saleOrders.show'
        }
    },

    /*
        ! Billing
    */
    {
        path: '/billing',
        name: 'Billing',
        component: () => import(/* webpackChunkName: "about" */ '../views/operations/billing/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Operaciones - Facturaciones',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'billings.show'
        }
    },
    {
        path: '/billing/:startDate/:endDate/:clientId/:id/:billingId/details',
        name: 'BillingDetailsBillingId',
        component: () => import(/* webpackChunkName: "about" */ '../views/operations/billing/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Operaciones - Detalles de Facturación',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'billings.show'
        }
    },
    {
        path: '/billing/:startDate/:endDate/:clientId/:id/details',
        name: 'BillingDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/operations/billing/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Operaciones - Detalles de Facturación',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'billings.show'
        }
    },
    {
        path: '/billing/new',
        name: 'BillingCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/operations/billing/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Operaciones - Facturas',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'billings.store'
        }
    },
];

const catalogRoutes = [
    /*
        ! Warehouses
    */
    {
        path: '/catalogs/warehouses',
        name: 'Warehouses',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/warehouses/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Almacenes',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'warehouses.show'
        }
    },
    {
        path: '/catalogs/warehouses/new',
        name: 'WarehousesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/warehouses/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Nuevo Almacén',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'warehouses.store'
        }
    },
    {
        path: '/catalogs/warehouses/:id/edit',
        name: 'WarehousesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/warehouses/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Editar Almacén',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'warehouses.edit'
        }
    },

    /*
        ! Banks
    */
    {
        path: '/catalogs/banks',
        name: 'Banks',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/banks/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Bancos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'banks.show'
        }
    },
    {
        path: '/catalogs/banks/new',
        name: 'BanksCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/banks/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Nuevo Banco',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'banks.store'
        }
    },
    {
        path: '/catalogs/banks/:id/edit',
        name: 'BanksEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/banks/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Editar Banco',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'banks.edit'
        }
    },

    /*
        ! Departments
    */
    {
        path: '/catalogs/departments',
        name: 'Departments',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/departments/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Departamentos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'departments.show'
        }
    },
    {
        path: '/catalogs/departments/new',
        name: 'DepartmentsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/departments/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Nuevo Departamento',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'departments.store'
        }
    },
    {
        path: '/catalogs/departments/:id/edit',
        name: 'DepartmentsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/departments/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Editar Departamento',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'departments.edit'
        }
    },

    /*
        ! Packagings
    */
    {
        path: '/catalogs/packagings',
        name: 'Packagings',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/packagings/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Embalajes',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'packagings.show'
        }
    },
    {
        path: '/catalogs/packagings/new',
        name: 'PackagingsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/packagings/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Nuevo Embalaje',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'packagings.store'
        }
    },
    {
        path: '/catalogs/packagings/:id/edit',
        name: 'PackagingsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/packagings/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Editar Embalaje',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'packagings.edit'
        }
    },

    /*
        ! Types
    */
    {
        path: '/catalogs/types',
        name: 'Types',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/types/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Tipos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'types.show'
        }
    },
    {
        path: '/catalogs/types/new',
        name: 'TypesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/types/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Nuevo Tipo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'types.store'
        }
    },
    {
        path: '/catalogs/types/:id/edit',
        name: 'TypesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/types/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Editar Tipo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'types.edit'
        }
    },

    /*
        ! Units
    */
    {
        path: '/catalogs/units',
        name: 'Units',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/units/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Unidades',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'units.show'
        }
    },
    {
        path: '/catalogs/units/new',
        name: 'UnitsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/units/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Nueva Unidad',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'units.store'
        }
    },
    {
        path: '/catalogs/units/:id/edit',
        name: 'UnitsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalog/units/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Editar Unidad',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'units.edit'
        }
    },
];

const permissionRoutes = [
    {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "about" */ '../views/permissions/users/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Usuarios',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'users.show'
        }
    },
    {
        path: '/users/new',
        name: 'UsersCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/permissions/users/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo Usuario',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'users.store'
        }
    },
    {
        path: '/users/:id/edit',
        name: 'UsersEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/permissions/users/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Usuario',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'users.edit'
        }
    },
    

    // /**
    //  * ! ROLES
    //  *  
    // */
    {
        path: '/roles',
        name: 'Roles',
        component: () => import(/* webpackChunkName: "about" */ '../views/permissions/roles/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Roles',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'roles.show'
        }
    },
    {
        path: '/roles/new',
        name: 'RolesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/permissions/roles/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo Rol',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'roles.store'
        }
    },
    {
        path: '/roles/:id/edit',
        name: 'RolesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/permissions/roles/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Rol',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'roles.edit'
        }
    },
];


const routes = [
    ...initialRoutes,
    ...dashboardRoutes,
    ...equipmentRoutes,
    ...purchasesRoutes,
    ...salesRoutes,
    ...operationRoutes,
    ...catalogRoutes,
    ...permissionRoutes,
    ...supervisionRoutes
];
export default routes;