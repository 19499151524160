const headers = [
    {
        text: 'Todos',
        align: 'left',
        value: 'all',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Crear',
        align: 'left',
        value: 'store',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Ver',
        align: 'left',
        value: 'show',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Editar',
        align: 'left',
        value: 'edit',
        sortable: false,
        class: 'table-b-border  black--text'
    },
    {
        text: 'Borrar',
        align: 'left',
        value: 'delete',
        sortable: false,
        class: 'table-b-border  black--text'
    },
   
]

const modules = [
  // {label:"Root", submodules:[ {key:"root", label: "Administrador"} ]},
    {
        props: {
            headers: [
                {
                    text: 'Dashboard',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
            
        },
        label: "Dashboard",
        submodules: [
            
            { key: "dashboards", label: "Dashboard", notIn:['store', 'edit', 'delete', 'index', 'update_amount', 'request']  },
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Supervisión',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
            
        },
        label: "Supervisión",
        submodules: [
            
            { key: "supervisions", label: "Supervisión", notIn:['store', 'edit', 'delete', 'update_amount', 'request']  },
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Inventario',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
            
        },
        label: "Inventario",
        submodules: [
            
            // { key: "consumables", label: "Consumible", notIn:['update_amount', 'request']  },
            { key: "equipments", label: "Equipos", notIn:['update_amount', 'request'] },
            { key: "entryOrders", label: "Ordenes de entrada", notIn:['update_amount', 'request'] },
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Compras',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers,
                {
                    text: 'Revisar',
                    align: 'left',
                    value: 'request',
                    sortable: false,
                    class: 'table-b-border  black--text'
                },
            ],
           
        },
        label: "Compras",
        submodules: [
           
            { key: "suppliers", label: "Proveedores", notIn:['update_amount', 'request']  },
            { key: "purchaseOrders", label: "Ordenes de compra", notIn:['update_amount', 'request']  },
            { key: "requisitions", label: "Requisiciones", notIn:['update_amount']  },
           
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Ventas',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Ventas",
        submodules: [
           
            { key: "prospects", label: "Prospectos", notIn:['update_amount', 'request']  },
            { key: "fee", label: "Cotizaciones", notIn:['update_amount', 'request']  },
            { key: "clients", label: "Clientes", notIn:['update_amount', 'request']  },
            { key: "quotations", label: "Tarifas", notIn:['update_amount', 'request']  },
            { key: "clientContacts", label: "Contactos", notIn:['update_amount', 'request']  },
            { key: "supplies", label: "Insumos", notIn:['update_amount', 'request']  },
            { key: "services", label: "Servicios", notIn:['update_amount', 'request']  },
           
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Operaciones',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers,
                {
                    text: 'Precios',
                    align: 'left',
                    value: 'update_amount',
                    sortable: false,
                    class: 'table-b-border  black--text'
                },
            ],
           
        },
        label: "Operaciones",
        submodules: [
            { key: "saleOrders", label: "Remisiones", notIn: ['request'] },
            { key: "billings", label: "Facturaciones", notIn:['edit', 'update_amount', 'request'] },
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Catálogos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Catálogos",
        submodules: [
            { key: "warehouses", label: "Almacenes", notIn:['update_amount', 'request']  },
            { key: "banks", label: "Bancos", notIn:['update_amount', 'request']  },
            { key: "departments", label: "Departamentos", notIn:['update_amount', 'request']  },
            { key: "packagings", label: "Embalajes", notIn:['update_amount', 'request']  },
            { key: "types", label: "Tipos", notIn:['update_amount', 'request']  },
            { key: "units", label: "Unidades", notIn:['update_amount', 'request']  },
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Accesos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
           
        },
        label: "Accesos",
        submodules: [
            { key: "roles", label: "Roles", notIn:['update_amount', 'request']  },
            { key: "users", label: "Usuarios", notIn:['update_amount', 'request']  },
        ],
    },
];

export default modules;
