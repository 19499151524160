<template>
    <div>
        <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            color="success"
            
            underlined
            dense
            
            :label="requiredCharLabel"
            append-icon="mdi-calendar"
            hide-details="auto" 
            v-bind="attrs"
            v-on="on"
            :rules="rule"
            :error-messages="errorMessages"
            :disabled="disabled"
            :class="`${classCustomText}`"
            v-on:keyup.enter="onEnter"
            :value="showInputValue"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="value"
          @input="menu = false"
          :min="minIn"
          :max="maxIn"
          :disabled="disabled"
        ></v-date-picker>
      </v-menu>
    </div>
</template>

<script>
export default {
    props: ['valueInput', 'classCustomText', 'equalValueToshowText', 'disabled', 'rule', 'label', 'errorMessages', 'minIn', 'maxIn', 'showTextTodayValue', 'valueText'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                console.log("value real change", value)
                this.$emit('valueChange', value)
            }
        },
        showInputValue:{
            get: function(){
                if(this.showTextTodayValue){
                    if(this.value == this.equalValueToshowText )
                        return this.valueText;
                    
                }
                return this.value
            },
            set: function(value) {
                this.$emit('showInputValueChange', value)
            }
        },
        requiredCharLabel:{
            get: function(){
                if(this.label == "" || this.label == undefined)
                    return "";
                const response = this.label + " *";
                for (let i = 0; i < (this?.rule?.length??[]); i++) {
                    if(this.rule[i]("") != true){
                        return response;
                    }
                }
                return this.label;
            }
        }
    },
    data: () =>({
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
    }),
    methods: {
        onEnter(){
            this.value = this.showInputValue
            this.$emit('valueChange', this.showInputValue);
            console.log("se emite", this.showInputValue)
        }
    }
}
</script>
<style lang="scss" >
   
  
</style>