<template>
   <div>
       <v-autocomplete 
        :label="requiredCharLabel" 
         
        color="success" 
        v-model="value" 
        hide-details="auto" 
        :items="items" 
        underlined
        dense
        chips
        item-color="secondary"
        :rules="rule"
        :disabled="disabled"
        multiple
        :error-messages="errorMessages"
        >
            <template v-slot:selection="data">
                <v-chip
                    class="mt-4 mb-1"
                    color="primary"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data)"
                    
                >
                <span class="black--text"> {{ data.item.text }}</span>
                </v-chip>
            </template>

            <template v-slot:prepend-item v-if="selectAll">
                <v-list-item
                ripple
                @mousedown.prevent
                @click="toggle"
                >
                
                <v-list-item-content>
                    <v-list-item-title>
                    Seleccionar todos/Remover todos
                    </v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:item="data">
                

                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.text"></v-list-item-title>
                  </v-list-item-content>
                </template>
            </template>
        
        </v-autocomplete>
        <v-progress-linear color="primary" indeterminate  height="2" v-if="loading"></v-progress-linear>
   </div>
</template>
<script>
export default {
    props: ['valueInput', 'rule', 'label', 'items', 'loading', 'disabled','errorMessages', 'selectAll'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                this.$emit('valueChange', value)
            }
        },
        selAll () {
            return this.items.length === this.value.length
        },
        requiredCharLabel:{
            get: function(){
                if(this.label == "" || this.label == undefined)
                    return "";
                const response = this.label + " *";
                for (let i = 0; i < (this?.rule?.length??[]); i++) {
                    if(this.rule[i]("") != true){
                        return response;
                    }
                }
                return this.label;
            }
        }
    },
    methods: {
      remove (item) {
        this.value.splice(item.index, 1)
        this.$emit('valueChange', this.value)
      },
      toggle () {
        this.$nextTick(() => {
          if (this.selAll) {
            this.value = []
          } else {
            this.value = this.items.map((itm) => {return itm.value})
          }
        })
      },
    },
}
</script>

<style lang="scss">

    //remove if label is removed
    // .v-input .v-progress-linear{
    //     background-color: var(--v-primary-lighten4)  !important;
    //     border-color: var(--v-primary-base)  !important;
    // }
    // .v-progress-linear .v-progress-linear__indeterminate--active .short{
    //     background-color: var(--v-primary-base) !important;
    //     border-color: var(--v-primary-base) !important;
    // }
    // .v-progress-linear .v-progress-linear__indeterminate--active .long{
    //     background-color: var(--v-primary-base) !important;
    //     border-color: var(--v-primary-base) !important;
       
    // }
    
</style>