
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class UserService extends BaseApi {


    constructor(){
        super();
    }


    async list()
    {
        return this.http.get(`${this.baseUrl}/users`)
        .catch(this.handleErrorResponse);
    }


    async get(slug)
    {
        return this.http.get(`${this.baseUrl}/users/${slug}/edit`)
        .catch(this.handleErrorResponse);
    }


    async store(data)
    {
        return this.http.post(`${this.baseUrl}/users/store`,data)
        .catch(this.handleErrorResponse);
    }


    async update(slug, data)
    {
        return this.http.put(`${this.baseUrl}/users/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }



    async delete(slug)
    {
        return this.http.delete(`${this.baseUrl}/users/${slug}/delete`)
        .catch(this.handleErrorResponse);
    }

    async changePassword(data, slug)
    {
        return this.http.put(`${this.baseUrl}/users/${slug}/restore`, data)
        .catch(this.handleErrorResponse);
    }



    async getDistributorsList()
    {
        return this.http.get(`${this.baseUrl}/getDistributors`)
        .catch(this.handleErrorResponse);
    }

}