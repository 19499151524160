<template>
    <div>
       

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name"
                    data-cy="name-input"
                ></textFieldForm>
            </v-col>
        </v-row>

    
        <v-row>
            <v-col>
                <table-items v-for="(item, index) in modules" :key="index" :props="item.props" @methodHandler="method_handler" :items="item.submodules">
                    <template v-slot:item.all="props">
                        <div class="table-item">
                            <v-checkbox
                                :key="updateCheck"
                                :data-cy="`${props.item.key}-all-check-input`"
                                :input-value="checkAllValue(props.item.key, props.item.notIn)"
                                @click="() => {selectAll(props.item.key,props.item.notIn)}"
                            ></v-checkbox>
                        </div>
                    </template>
                    <template v-slot:item.store="props" >
                        <div class="table-item">
                            <v-checkbox
                                v-if="notIn('store',props.item.notIn)"
                                :data-cy="`${props.item.key}-store-check-input`"
                                v-model="values.permissions[props.item.key].store"
                                @click="() => {update()}"
                            ></v-checkbox>
                        </div>
                    </template>
                    <template v-slot:item.show="props">
                        <div class="table-item">
                            <v-checkbox
                                v-if="notIn('show',props.item.notIn)"
                                :data-cy="`${props.item.key}-show-check-input`"
                                v-model="values.permissions[props.item.key].show"
                                @click="() => {update()}"
                            ></v-checkbox>
                        </div>
                    </template>
                    <template v-slot:item.edit="props"  >
                        <div class="table-item">
                            <v-checkbox
                                v-if="notIn('edit',props.item.notIn)"
                                :data-cy="`${props.item.key}-edit-check-input`"
                                v-model="values.permissions[props.item.key].edit"
                                @click="() => {update()}"
                            ></v-checkbox>
                        </div>
                    </template>
                    <template v-slot:item.delete="props"  >
                        <div class="table-item">
                            <v-checkbox
                                v-if="notIn('delete',props.item.notIn)"
                                :data-cy="`${props.item.key}-delete-check-input`"
                                v-model="values.permissions[props.item.key].delete"
                                @click="() => {update()}"
                            ></v-checkbox>
                        </div>
                    </template>

                    <template v-slot:item.update_amount="props"  >
                        <div class="table-item">
                            <v-checkbox
                                v-if="notIn('update_amount',props.item.notIn)"
                                :data-cy="`${props.item.key}-update_amount-check-input`"
                                v-model="values.permissions[props.item.key].update_amount"
                                @click="() => {update()}"
                            ></v-checkbox>
                        </div>
                    </template>

                    <template v-slot:item.request="props"  >
                        <div class="table-item">
                            <v-checkbox
                                v-if="notIn('request',props.item.notIn)"
                                :data-cy="`${props.item.key}-request-check-input`"
                                v-model="values.permissions[props.item.key].request"
                                @click="() => {update()}"
                            ></v-checkbox>
                        </div>
                    </template>
                   
                </table-items>
                
            </v-col>
        </v-row>


    </div>
</template>

<script>
import TableItems from '../../../components/TableItems.vue';
import modules from './modules';
/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
    components: {
       'table-items': TableItems
    },
    computed: {
        allItem: function(test){
            console.log(this.values, test);
            
            return true;
        },
       
       
    },
    data(){
        return {
            updateCheck: 0,
            modules:modules,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                 permissions: [
                    (value) =>
                    (value && value.length > 0) || "Debes agregar permisos al rol",
                ],
               
				
				
            },
          
        }
    },
   
    mounted(){
        
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        update()
        {
            this.updateCheck = this.updateCheck + 1;
        },
        checkAllValue(key, notIn = [])
        {
            let yesAll = true;
            const actions = ['show', 'store', 'edit', 'delete', 'update_amount', 'request'];
            for (let i = 0; i < actions.length; i++) {
                const action = actions[i];
                if(!notIn.includes(action)){
                    if(!this.values.permissions[key][action]){
                        yesAll = false;
                        break;
                    }
                }
            }
            // if(this.values.permissions[key].show &&
            //     this.values.permissions[key].store &&
            //     this.values.permissions[key].edit &&
            //     this.values.permissions[key].delete )
            //     return true;
            return yesAll
        },
        selectAll(key, notIn = []) {
            if (Object.hasOwnProperty.call(this.values.permissions, key)) {
                if (
                    this.checkAllValue(key, notIn)
                ) {
                    this.values.permissions[key] = {
                        index:false,
                        show: false,
                        store: false,
                        edit: false,
                        delete: false,
                        update_amount:false,
                        request:false
                        
                    };
                } else {
                    this.values.permissions[key] = {
                        index:true,
                        show: true,
                        store: true,
                        edit: true,
                        delete: true,
                        update_amount:true,
                        request:true,
                        
                    };
                }
            } else {
                this.values.permissions[key] = {
                index:true,
                show: true,
                store: true,
                edit: true,
                delete: true,
                update_amount:true,
                request:true,
                
                };
            }

           console.log(key, this.values.permissions[key]);
        },

        notIn(action, notIn=[])
        {
            if(notIn.includes(action))
                return false;

            return true;
        }
       
    }
}
</script>

<style>

</style>