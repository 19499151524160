<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col  md="8" lg="8" xl="5" class="py-5 my-0">
                        <v-row>
                            <v-col class="text-left py-0 my-0" md="6" align-self="center">
                                <div class="font-weight-bold body-1">
                                    Detalles de la requisición
                                </div>
                            </v-col>
                            <v-col class="text-right py-0 my-0" md="6">
                                <div class="font-weight-bold body-1">
                                    <primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row> 
                 <v-row justify="center">
                        <v-col cols="mx-0 px-0 py-0 my-1"  md="8" lg="8" xl="5" >
                            <div class="divider-secondary"></div>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="mx-0 px-0 py-0 my-0"  md="8" lg="8" xl="5">
                            <div class="divider-secondary"></div>
                        </v-col>
                    </v-row>

                <v-row justify="center">
                    <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0 pb-0" md="8" lg="8" xl="5">
                        <p class="mb-0 pb-0">Datos generales</p>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="8" lg="8" xl="5" class="py-0 my-0" >

                    <details-item data-cy="details-list" :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>

                <!-- PRODUCTS -->
                <v-row justify="center">
                    <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" cols="12" md="8" lg="8" xl="5">
                        <p class="mb-0 pb-0">Catálogo</p>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col class="pb-2 pt-0 mt-0" cols="12" md="8" lg="8" xl="5">
                        <table-items :props="tableProps" :items="products" @methodHandler="method_handler" >


                             
                            <template v-slot:item.status="props" >

                                <div class="table-item">
                                    <span data-cy="details-table-status-item" :class="`${getStatusColor(props.item.status)} font-weight-bold`">{{props.item.status}}</span>
                                </div>
                            </template>


                            <template v-slot:item.actions="item" >


                                <RequisitionTableActions
                                    :itemInput="item.item"
                                    :parameters="item.parameters"
                                    :edit="true"
                                    @methodHandler="method_handler"
                                    :nudgeLeft="7"
                                    :show="true"
                                ></RequisitionTableActions>

                                <!-- <v-tooltip top v-for="(action, i) of item.item.actions" :key="i">
                                    <template v-slot:activator="{ on }">
                                        <v-btn :data-cy="action.dataCy" x-small v-if="action.action != 'openComments'" class="mx-0" icon @click="method_handler({
                                                    methodName: action.action, 
                                                    parameters:  item.item.parameters
                                                })" :disabled="action.disabled"
                                        >
                                            <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                        </v-btn>

                                        <v-menu
                                            v-else
                                            v-model="item.item.menu"
                                            left
                                            offset-x
                                            nudge-top="10"
                                            nudge-left="12"
                                            :close-on-content-click="false"
                                            content-class="menuclasss"
                                            max-width="500"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                   
                                                    <div :class="`${item.item.menu ? 'requisitionsDetails-testclass d-none d-md-inline-flex' : 'requisitionsDetails-notshow'} triangle-left ` ">
                                                    </div>
                                                    <v-btn v-bind="attrs" :data-cy="action.dataCy" x-small class="mx-0" icon  :disabled="action.disabled" >
                                                        <v-icon :color="action.icon.color" @click="(a)=>{on.click(a); }">{{ action.icon.icon }}</v-icon>
                                                    </v-btn>
                                            
                                            </template>

                                            <div class="requisitionsDetails-comments-container" :key="messagesWindowKey">
                                                <p class="py-2 my-0 font-weight-bold">
                                                    Comentarios sobre el producto {{item.item.name}}
                                                </p>
                                                
                                                <div class="requisitionsDetails-comments-chat" ref="listChat" v-if="openComments(item)"
                                                
                                                >
                                                    <div v-for="(message, index) in item.item.localMessages" :key="index" >
                                                        <div :class="`my-3  ${message.position == 'right' ? 'text-right' : 'text-left'}`">
                                                            <p class="pb-0 mb-0 requisitionsDetails-comments-comment">{{message.comments}}</p>
                                                            <p class="pb-0 mb-0 requisitionsDetails-comments-user">{{message.user}} ({{message.date}})</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                
                                            </div>
                                        </v-menu>
                                        
                                    </template>
                                    <span>{{ action.name }}</span>
                                </v-tooltip> -->

                            
                            </template>
                           
                            
                        </table-items>
                        
                    </v-col>
                </v-row>


                
               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="8" lg="8" xl="5" class="d-flex justify-end">
                    
					
				</v-col>
			</v-row>


            <!-- images modal -->
            <modal data-cy="details-modal" :props="modalProps" @modalResponse="method_handler">
                <!-- form -->
                <template slot="cardBody">
                    <div>
                        <v-row>
                            <v-col >
                                <imageSlide
                                    :mini="true"
                                    :images=" selectedItem.imagesUrls"
                                ></imageSlide>
                            </v-col>
                        </v-row>
                        
                    </div>
                </template>
                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end" data-cy="details-modal-actions">
                        <!-- <v-col cols="12" sm="4">
                            <secondaryButton
                            data-cy="cancel-modal-btn"
                            :props="cancelModal"
                            ></secondaryButton>
                        </v-col> -->
                        <v-col cols="12" sm="4">
                            <primaryButton
                            data-cy="accept-modal-btn"
                            :props="acceptModal"
                            ></primaryButton>
                        </v-col>
                    </v-row>
                </template> 
            </modal>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
import RequisitionTableActions from "./RequisitionTableActions.vue"
export default {
    components: {
        DetailsItem,
        RequisitionTableActions
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            messagesWindowKey:33,
            selectedItem: {},
            items: [
               
                
            ],

            products: [],


            modalProps: {
				visible: false,
				width: '700',
				title: 'Imágenes',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				
			},
			
			
			acceptModal: {
                text: "Aceptar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				textColor:'black',
                textColorLevel: '0',
				loading:false,
                cy:"accept-image-modal-btn",
                click: ()=>{this.modalProps.visible = false}
            },
            actions: [
                {
					name: 'Descripciones',
					icon: {
						color: 'secondary',
						icon: 'mdi-message-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'openComments',
                    dataCy: "descriptions-link"
				},
				{
					name: 'Imágenes',
					icon: {
						color: 'secondary',
						icon: 'mdi-image-multiple-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'imagesItemModal',
                    dataCy: "images-link"
				},
				
			],

            tableProps: {
				headers: [
                     {
						text: 'Partida',
						align: 'left break-words',
						value: 'part',
                        width: "10%",
						class: 'table-b-border black--text',
                        sortable: false,
					},
					{
						text: 'Descripción',
						align: 'left break-words',
						value: 'name',
                        
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Unidad',
						align: 'center',
						value: 'unit',
                        
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
                        
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Estatus',
						align: 'center',
						value: 'status',
                        
						sortable: false,
						class: 'table-b-border black--text'
					},
                    
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '15%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},

         
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Requisitions",	
                click: () => {}
            },
           
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        tst(){
            console.log("okok");
        },
        imagesItemModal(parameters)
		{
			console.log(parameters);
            let found = this.products.find(fItem => fItem.supply_id == parameters.id)
            if(found == undefined)
                return;
            this.selectedItem = found;
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
			}
			this.modalProps.visible = true;
			
		},
        openComments(){
            try {
                setTimeout(() => {
                    this.tobottom();
                }, 100);
            } catch (error) {
                console.log(error)
            }

            return true;
        },
        tobottom () {
            try {
                this.$refs.listChat[0].scrollTop = this.$refs.listChat[0].lastElementChild.offsetTop;
            } catch (error) {
                console.log(error);
            }
        },
        getStatusColor(status){
            switch (status) {
                case "Pendiente":
                    return ""
                case "Aprobada":
                    return "green--text";
                case"Rechazada":
                    return "red--text";
                default:
                    break;
            }
        },
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.requisition.show(this.id)
			.then((response) => {
                console.log(response);
                


               
                this.items= [
                    { concepto: 'Fecha de creación', index: 'description', valor: Utils.globalDateFormat(response.data.data.created)},
                    { concepto: 'Fecha para la que se solicita', index: 'sku', valor: Utils.globalDateFormat(response.data.data.date) },
                    { concepto: 'Solicitante', index: 'sku', valor: response.data.data.requester },
                    { concepto: 'Almacén', index: 'sku', valor: response.data.data.warehouse },
                    { concepto: 'Observaciones', index: 'sku', valor: response.data.data.observations },
                    
                ];

            
                //products 
                this.products = response.data.supplies.map((item,i) =>{
                    return {
                        actions: JSON.parse(JSON.stringify(this.actions)),
                        parameters: { id: item.supply_id, name: item.supply, index: i},

                        localMessages: [...item?.comments?.map((ms) => {return{...ms, isNew:false}})],
                        imagesUrls: item?.visual_support_url?.map((img) => {return {url:img}}),
                        supply_id: item.supply_id,
                        unit: item.unit,
                        quantity : item.quantity,
                        visual_supportBackup: item.visual_support,
                        visual_support: [],
                        comments: item.comments,
                        name: item.supply,
                        part:i+1,
                        status: item.status
                    }
                });

                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style lang="scss">
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }

    .requisitionsDetails{
        &-information{
            padding-top: 18px;
            line-height: 15px !important;

            &-2{
                padding-top: 8px;
                line-height: 15px !important;
            }
        }



        &-comments-container{
            padding: 15px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #F7F7F7;
            min-height: 200px;
            min-width: 31em;
        }


        &-comments-chat{
            max-height: 300px;
            overflow-y: scroll;
        }

        &-comments-comment{
            font-weight: 700;
            white-space: pre-wrap;
            overflow-wrap: break-word;
        }
        &-comments-user{
            font-size: 0.75rem;
        }
        &-notshow{
            display: none !important;
        }
        &-testclass{
            display: inline-block;
            transform: translateY(6px);
        }
    }
    .menuclasss{
        box-shadow: none !important;
        border: solid 1px black;
    }


    .triangle-left {
        width: 0;
        height: 0;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
        border-left: 11px solid black;
        position: relative;
        display: inline-block;
        // transform: translateY(8px);
        transform: translate(-1px, 7px);
        z-index: 10;
    }

    .triangle-left:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #F7F7F7;
        position: absolute;
        top: -10px;
        left: -11px;
        z-index: 10;
    }
</style> 