<template>
    <div>
        <v-row justify="center">
           
            <v-col  class="py-2">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                    :showTextTodayValue="true"
                    valueText="urgente"
                    :equalValueToshowText="ut.getNowDate()"
                ></datePickerForm>
                <p class="caption requisitionsForm-information-2 mb-0">
                   Deja como urgente solamente si necesitas tu material de manera inmediata,
                   de lo contrario especifica la fecha para que lo necesites
                </p>
            </v-col>

            <v-col class="py-2">
                <autocompleteOneForm
                    label="almacén"
                    :loading="loadingWarehose"
                    :valueInput="values.warehouse_id"
                    :rule="rules.required"
                    :items="serverItems.warehouses"
                    :errorMessages="serverErrors.warehouse_id"
                    @valueChange="(v) => {values.warehouse_id = v; this.clear(); delete serverErrors.warehouse_id}"
                    data-cy="warehouse_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textAreaForm 
                    @valueChange="(v) => {values.observations = v; delete serverErrors.observations}" 
                    :valueInput="values.observations" 
                    :rule="[]" 
                    label="Observaciones"
                    :errorMessages="serverErrors.observations"
                    data-cy="observations-input"
                ></textAreaForm>
            </v-col>
        </v-row>
       

      

        <v-row justify="center">
            <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                <p class="mb-0 pb-0">Insumos</p>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2 mt-3" cols="12" sm="12"  xl="12">
                <v-row>
                   <v-col class="py-0 pr-5">
                     <autocompleteOneForm
                        label="Insumos"
                        :loading="loadingSupplies"
                        :valueInput="productSelected"
                        :rule="[]"
                        :items="serverItems.supplies"
                        :returnObject="true"
                        data-cy="productSelected-input"
                        @valueChange="(v) => {productSelected = v;}"
                    ></autocompleteOneForm>
                   </v-col>
                    <iconButton
                        data-cy="productSelected-input-btn"
                        :props="{
                        color:'primary',
                        icon:'mdi-plus',
                        height:'41',
                        
                        click: () => {addProduct();delete serverErrors.supplies}
                        }"
                    ></iconButton>
                </v-row>
                <p class="caption requisitionsForm-information">
                    En caso de no encontrar el elemento que necesitas en la lista, selecciona el "producto genérico"
                    y agrega detalles como descripciones, enlaces o fotografías para ayudar a identificiar
                    el producto más rápido y correctamente
                </p>
            </v-col>
            <!-- <v-col class="py-2">
                
            </v-col> -->
        </v-row>

        
        <v-row>
            <v-col class="py-2">
                <table-items data-cy="table-items" :props="tableProps" @methodHandler="method_handler" :items="values.supplies">
                    
                    <template v-slot:item.quantity="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.quantity = v; delete serverErrors.supplies}" 
                                :valueInput="props.item.quantity" 
                                :rule="[...rules.quantity]" 
                                data-cy="table-quantity-input"
                                
                            ></textFieldForm>
                        </div>
                    </template>

                    <template v-slot:item.actions="item" >

                        <RequisitionTableActions
                            :itemInput="item.item"
                            :parameters="item.parameters"
                            :edit="false"
                            @methodHandler="method_handler"
                            :nudgeLeft="7"
                        ></RequisitionTableActions>



                      
                    </template>
                    
                </table-items>
                
            </v-col>
            
        </v-row>
        <span class="red--text caption" v-if="serverErrors[`supplies`]">{{serverErrors[`supplies`]}}</span>

        <!-- images modal -->
        <modal data-cy="index-modal" :props="modalProps" @modalResponse="method_handler">
            <!-- form -->
            <template slot="cardBody">
                <div>
                    <v-row>
                       
                        <v-col >
                             <!-- <v-img
                                :src="item"
                                height="100"
                                width="100"
                            ></v-img> -->
                            <imageSlide
                                :mini="true"
                                :images=" selectedItem.imagesUrls"
                            ></imageSlide>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-2">
                               <fileInputForm
                    
                                    label="Subir imágenes"
                                    :multiple="true"
                                    :valueInput="selectedItem.visual_support"
                                    :url="selectedItem.visual_support_url"
                                    :rule="rules.imagesFilesLengthPrimary"
                                    @valueChange="(v) => {selectedItem.visual_support = v; delete serverErrors.visual_support}"
                                    :errorMessages="serverErrors.visual_support"
                                    v-on:update:url="selectedItem.visual_support_url = $event"
                                    :disableDelete="true"
                                    data-cy="visual_support-input"
                                ></fileInputForm>
                        </v-col>
                    </v-row>
                </div>
            </template>
            <!-- Buttons -->
            <template slot="cardActions">
                <v-row justify="end" data-cy="index-modal-actions">
                    <!-- <v-col cols="12" sm="4">
                        <secondaryButton
                        data-cy="cancel-modal-btn"
                        :props="cancelModal"
                        ></secondaryButton>
                    </v-col> -->
                    <v-col cols="12" sm="4">
                        <primaryButton
                        data-cy="accept-modal-btn"
                        :props="acceptModal"
                        ></primaryButton>
                    </v-col>
                </v-row>
            </template> 
        </modal>

        
       

    </div>
</template>

<script>
// import ImageSlide from '../../../components/ImageSlide.vue';
import Utils from '../../../helpers/Utils';
import RequisitionTableActions from "./RequisitionTableActions.vue"
/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
    components:{
        // ImageSlide
        RequisitionTableActions
    },
    data(){
        return {
            ut: Utils,
            menu:false,
            messagesWindowKey:33,
            loadingWarehose:false,
            loadingProducts:false,
            loadingSupplies:false,
            productSelected: {},
            selectedItem: {},
            comment: "",
            modalProps: {
				visible: false,
				width: '700',
				title: 'Imágenes',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				
			},
			
			
			acceptModal: {
                text: "Aceptar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				textColor:'black',
                textColorLevel: '0',
				loading:false,
                cy:"accept-image-modal-btn",
                click: ()=>{this.modalProps.visible = false}
            },
            actions: [
                {
					name: 'Descripciones',
					icon: {
						color: 'secondary',
						icon: 'mdi-message-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'openComments',
                    dataCy: "descriptions-link"
				},
				{
					name: 'Imágenes',
					icon: {
						color: 'secondary',
						icon: 'mdi-image-multiple-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'imagesItemModal',
                    dataCy: "images-link"
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    dataCy: "delete-link"
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'Partida',
						align: 'left break-words',
						value: 'part',
                        width: "10%",
						class: 'table-b-border black--text',
                        sortable: false,
					},
					{
						text: 'Descripción',
						align: 'left break-words',
						value: 'name',
                        
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Unidad',
						align: 'center',
						value: 'unit',
                        
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
                        
						sortable: false,
						class: 'table-b-border black--text'
					},
                    
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '15%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            closeCommentsBtnData: {
                text: "Aceptar",
                icon: "",	
                click: ()=>{},
                block:false,
                loading: false,
            },
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                initial_cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
				
				
            },

            serverItems:{
               
                warehouses: [],
                products: [],
                supplies:[],

            }
            

          
        }
    },
    watch: {
        ["values.date"]: function (v) {
            // console.log(v, new Date().toISOString().split('T')[0], Utils.getNowDate())
            this.values.priority = 0;
            if(v == Utils.getNowDate()){
                this.values.priority = 1;
            }
            console.log("chanbio calues date")
            
        },
        ["values.supplies"]: function (v) {
            for (let i = 0; i < this.values.supplies.length; i++) {
                this.values.supplies[i].part = i + 1;
            }
        },

    },
    mounted(){
        this.getWarehouses();
        // this.loadProducts(); //delete
        this.getSupplies();
        if(this.edit){
            // this.getProducts(this.values.supplier_id)
            this.loadProducts();
        }
    },
    methods: {
		imagesItemModal(parameters)
		{
			console.log(parameters);
            let found = this.values.supplies.find(fItem => fItem.supply_id == parameters.id)
            if(found == undefined)
                return;
            this.selectedItem = found;
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
			}
            console.log(this.selectedID, this.selectedItem, "slected")
			this.modalProps.visible = true;
			
		},
        closeItem(item){
            item.menu = false;
        },
        addComments(item){
            if(this.comment == "")
                return;
            // console.log(item, "add cooment")
            // this.values.localMessages = 
            item.comments = JSON.parse(JSON.stringify(this.comment));
            if(this.edit){
                item.localMessages.push({
                    comments: item.comments,
                    date:  Utils.getNowDate(),
                    position: "right",
                    user: this.$store.state.loggedUsername,
                })
            }else{
                item.localMessages = [
                    {
                        comments: item.comments,
                        date:  Utils.getNowDate(),
                        position: "right",
                        user: this.$store.state.loggedUsername,
                    }
                ];
            }


            this.comment = "";
            this.messagesWindowKey = this.messagesWindowKey + 1;
            item.menu = false;
            console.log(item, "completo")
        },
        closeComments(){
            this.menu = false;
        },
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        loadProducts()
        {
            this.values.supplies = this.values.supplies.map((item,i) => {
                // x['parameters'] = { id: x.slug, name: x.sku, index: i};
                
              
               
                return {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: { id: item.supply_id, name: item.supply, index: i},

                    localMessages: [...item?.comments.map((ms) => {return{...ms, isNew:false}})],
                    imagesUrls: item?.visual_support_url.map((img) => {return {url:img}}),
                    supply_id: item.supply_id,
                    unit: item.unit,
                    quantity : item.quantity,
                    visual_supportBackup: item.visual_support,
                    visual_support: [],
                    comments: item.comments,
                    name: item.supply,
                    part:i+1,
                }
            })

            console.log(this.values.supplies, "supppp")
        },
        
        getWarehouses()
        {
            // this.loadingWarehouses = true;
            // this.$api.warehouse.getSelect()
            //     .then((resp) => {
            //         this.serverItems.warehouses = resp.data;
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     })
            //     .finally(() => {
            //         this.loadingWarehouses = false;
            //     })
            // ;
            this.serverItems.warehouses = this.$store.state.userData.warehousesRaw;
            //set default
            if(this.$store.state.warehouse != "all")
                this.values.warehouse_id = this.$store.state.warehouse;
        },
        getSupplies()
        {
            this.loadingSupplies = true;
            this.$api.supply.getSupplies()
                .then((resp) => {
                    this.serverItems.supplies = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingSupplies = false;
                })
            ;
          
        },

        addProduct()
        {
            if(!("value" in this.productSelected))
                return;
            
            let found = this.values.supplies.find(fItem => fItem.supply_id == this.productSelected.value)
            if(found != undefined)
                return;

            let item = this.productSelected;
            let newProd = {
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: item.value, name: item.text, index: 99},

                supply_id: item.value,
                unit: item.unit,
                quantity : 1,
                visual_support: [],
                comments: "",
                name: item.text,
                part:0,
            }

            this.values.supplies.push(newProd);

            
        },

        clear()
        {
            // this.serverItems.products = [];
            // this.values.supplies = [];
            // this.productSelected = {};
        },
        

        getProducts(supplier)
        {
           
            if(supplier == "" || supplier == null || supplier == undefined)
                return;

            this.loadingProducts = true;
            this.$api.supplier.getProducts(supplier)
                .then((resp) => {
                    this.serverItems.products = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingProducts = false;
                })
            ;
        },

        deleteItem(item)
        {
            let index = this.values.supplies.findIndex(prod => prod.supply_id == item.id);

            console.log(index, item);
            this.values.supplies.splice(index, 1);
        },
        updated() {
            // whenever data changes and the component re-renders, this is called.
            // this.$nextTick(() => this.tobottom());
            // this.tobottom()
        },
        scrollToEnd: function () {
            // scroll to the start of the last message
            try {
                this.$refs.listChat[0].scrollTop = this.$refs.listChat[0].lastElementChild.offsetTop;
            } catch (error) {
                console.log(error);
            }
        },
        tobottom () {
            try {
                this.$refs.listChat[0].scrollTop = this.$refs.listChat[0].lastElementChild.offsetTop;
            } catch (error) {
                console.log(error);
            }
        },

        openComments(item){
            console.log(this.$refs.listChat)
            try {
                setTimeout(() => {
                    this.tobottom();
                    
                }, 100);
            } catch (error) {
                
            }

            return true;
        }
       
    }
}
</script>

<style lang="scss">
    .requisitionsForm{
        &-information{
            padding-top: 18px;
            line-height: 15px !important;

            &-2{
                padding-top: 8px;
                line-height: 15px !important;
            }
        }



        &-comments-container{
            padding: 15px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #F7F7F7;
            min-height: 200px;
            min-width: 31em;
        }


        &-comments-chat{
            max-height: 300px;
            overflow-y: scroll;
        }

        &-comments-comment{
            font-weight: 700;
            white-space: pre-wrap;
            overflow-wrap: break-word;
        }
        &-comments-user{
            font-size: 0.75rem;
        }
        &-notshow{
            display: none !important;
        }
        &-testclass{
            display: inline-block;
            transform: translateY(6px);
        }
    }
    .menuclasss{
        box-shadow: none !important;
        border: solid 1px black;
    }


    .triangle-left {
        width: 0;
        height: 0;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
        border-left: 11px solid black;
        position: relative;
        display: inline-block;
        // transform: translateY(8px);
        transform: translate(-1px, 7px);
        z-index: 10;
    }

    .triangle-left:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #F7F7F7;
        position: absolute;
        top: -10px;
        left: -11px;
        z-index: 10;
    }

    .arrow-right {
        width: 0; 
        height: 0; 
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 12px solid #F7F7F7;
        margin-right: 1px;
        
    }

    .inner-triangle {
        position: relative;
        top: -20px;
        left: 2px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid red;
        margin-right: 1px;
    }
</style>