<template>
    <div>
<!-- 
        <v-row v-if="edit">
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.id = v; delete serverErrors.id}" 
                    :valueInput="values.id" 
                    :rule="[]" 
                    label="Clave"
                    :errorMessages="serverErrors.id"
                    :disabled="true"
                    data-cy="id-input"
                ></textFieldForm>
            </v-col>
        </v-row> -->

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Categoría"
                    :valueInput="values.category"
                    :rule="rules.required"
                    :items="serverItems.categories"
                    :errorMessages="serverErrors.category"
                    @valueChange="(v) => {values.category = v; delete serverErrors.category}"
                    data-cy="category-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name"
                    data-cy="name-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Tipo de cuenta"
                    :valueInput="values.account_type"
                    :rule="rules.required"
                    :items="serverItems.accounts"
                    :errorMessages="serverErrors.account_type"
                    @valueChange="(v) => {values.account_type = v; delete serverErrors.account_type}"
                    data-cy="account_type-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>



        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.price_usd = v; delete serverErrors.price_usd}" 
                    :valueInput="values.price_usd" 
                    :rule="rules.cost"
                    label="Costo en dólares (USD)"
                    :errorMessages="serverErrors.price_usd"
                    :prefix="values.price_usd === '' ? '' : '$'"
                    data-cy="price_usd-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.price_mxn = v; delete serverErrors.price_mxn}" 
                    :valueInput="values.price_mxn" 
                    :rule="rules.cost"
                    label="Costo en pesos (MXN)"
                    :errorMessages="serverErrors.price_mxn"
                    :prefix="values.price_mxn === '' ? '' : '$'"
                    data-cy="price_mxn-input"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Unidad"
                    :loading="loadingUnits"
                    :valueInput="values.unit_id"
                    :rule="rules.required"
                    :items="serverItems.units"
                    :errorMessages="serverErrors.unit_id"
                    @valueChange="(v) => {values.unit_id = v; delete serverErrors.unit_id}"
                    data-cy="unit_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.restock_alert = v; delete serverErrors.restock_alert}" 
                    :valueInput="values.restock_alert" 
                    :rule="rules.onlyNumbers" 
                    label="Alerta de resurtido"
                    :errorMessages="serverErrors.restock_alert"
                    data-cy="restock_alert-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row justify="space-between">
            <v-col class=" mb-0" >
                <p class="py-0 my-0">decremento automático de stock por consumo</p>
            </v-col>

            <v-col class="Supplies-checkbox" >
                <div class="bill-mark-all-cont">
                    <checkboxForm
                        label=""
                        :valueInput="values.self_decrease"
                        
                        @valueChange="(v) => {values.self_decrease = v; delete serverErrors[`self_decrease`];}"
                        :errorMessages="serverErrors[`self_decrease`]"
                        data-cy="self_decrease-input"
                    ></checkboxForm>
                </div>
            </v-col>
        </v-row>

        <v-row justify="space-between">
            <v-col class=" mb-0" >
                <p class="py-0 my-0">notificación de resurtido</p>
            </v-col>

            <v-col class="Supplies-checkbox" >
                <div class="bill-mark-all-cont">
                    <checkboxForm
                        label=""
                        :valueInput="values.restock_notification"
                        
                        @valueChange="(v) => {values.restock_notification = v; delete serverErrors[`restock_notification`];}"
                        :errorMessages="serverErrors[`restock_notification`]"
                        data-cy="restock_notification-input"
                    ></checkboxForm>
                </div>
            </v-col>
        </v-row>



       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            loadingUnits: false,
            serverItems: {
                accounts:[
                    {text: "Ingreso", value: "income"},
                ],
                categories:[
                    {text: "Servicios", value: "services"},
                    {text: "Activos", value: "assets"},
                    {text: "Mantenimientos", value: "maintenances"},
                    {text: "Insumos", value: "inputs"},
                ],
                units: [],
            },
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                onlyNumbers: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],

               
              
                
				
            },

            

          
        }
    },
   
    mounted(){
        this.getUnits();
    },
    methods: {
        getUnits()
        {
            this.loadingUnits = true;
            this.$api.unit.getSelect()
                .then((resp) => {
                    this.serverItems.units = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingUnits = false;
                })
            ;
        }
       

    }
}
</script>

<style lang="scss">
    .Supplies{
        &-checkbox{
            justify-items: end;
        }
    }
</style>