<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Detalles de Órden de entrada
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="6" >

                        <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>

                <v-row justify="center">
                    <v-col class="py-2" cols="12" md="6">
                        <table-items :props="tableProps" :items="products">
                            
                           
                            
                        </table-items>
                        
                    </v-col>
                </v-row>
                    
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
// import Utils from '../../../helpers/Utils';
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
           
            items: [
               
                
            ],

            products: [],

            tableProps: {
				headers: [
                     {
						text: 'Insumo',
						align: 'left break-words',
						value: 'supply',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					
                    {
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
                        width: "18%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                   
					
				],
				items: [],
				search: '',
				loading: false
			},
           
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "EntryOrders",	
                click: () => {}
            },
           
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.entryOrder.show(this.id)
			.then((response) => {
                console.log(response);
                


               
                this.items= [
                    { concepto: 'Proveedor', index: 'provider', valor: response.data?.data?.supplier??"" },
                    { concepto: 'Distribuidor', index: 'provider', valor: response.data?.data?.distributor_id??"" },
                    { concepto: 'Orden de compra', index: 'provider', valor: response.data?.data?.purchase_order_id??"" },
                    { concepto: 'Fecha', index: 'description', valor:response.data?.data?.date??""},
                    
                ];


                this.products = response.data.supplies;
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 