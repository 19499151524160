<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center">
                    <v-col class="left-center" cols="12" md="8">
                        <v-row>
                            <v-col cols="12" sm="5" class="text-center text-md-left px-0">
                                <div class="text-uppercase font-weight-bold black--text text-h6">
                                    <span>Revisar requisición</span>
                                </div>
                            </v-col>

                            <v-col class="px-0 mx-0">
                                <v-row justify="center" align="end">
                                    <v-col cols="12" class="text-right">
                                        <secondary-button data-cy="cancel-btn" :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                                        <primary-button data-cy="accept-btn" :props="saveBtnData" class=""></primary-button>  
                                    </v-col>
                                </v-row>
                                
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="mx-0 px-0 py-0 my-1" >
                                <div class="divider-secondary"></div>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="mx-0 px-0 py-0 my-0" >
                                <div class="divider-secondary"></div>
                            </v-col>
                        </v-row>
                    </v-col>
                    
                </v-row>

                
                <v-form ref="form" @submit.prevent="save">
                    <v-row justify="center">
                        <v-col cols="12" md="8" class="px-0">
                            <v-row>
                                <v-col cols="12" md="8" >
                                    <div class="text-uppercase font-weight-bold black--text text-h6">
                                        Datos generales
                                    </div>
                                </v-col>

                            </v-row>
                            <v-row class="py-1" align="center" justify="center">
                                <v-col class="py-1" cols="12" md="6">
                                    <datePickerForm
                                        label="Fecha"
                                        :valueInput="values.date"
                                        @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                                        :errorMessages="serverErrors['date']"
                                        data-cy="date-input"
                                        :showTextTodayValue="true"
                                        valueText="urgente"
                                        :disabled="true"
                                    ></datePickerForm>
                                </v-col>
                                <v-col class="py-1" cols="12" md="6">
                                    <textFieldForm 
                                        @valueChange="(v) => {values.requester = v; delete serverErrors.requester}" 
                                        :valueInput="values.requester" 
                                        label="Solicitante"
                                        :errorMessages="serverErrors.requester"
                                        data-cy="requester-input"
                                        :disabled="true"
                                    ></textFieldForm>
                                </v-col>
                            </v-row>
                            <v-row class="py-1" align="center" justify="center">
                                <v-col class="py-1" cols="12" md="6">
                                <datePickerForm
                                        label="Prioridad"
                                        :valueInput="values.date"
                                        @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                                        :errorMessages="serverErrors['date']"
                                        data-cy="date-input"
                                        :showTextTodayValue="values.priority"
                                        :valueText="`${values.date} urgente`"
                                        :disabled="true"
                                        :equalValueToshowText="values.date"
                                        :classCustomText="`${values.priority ? 'specialRed' : ''}`"
                                    ></datePickerForm>
                                </v-col>
                                <v-col class="py-1" cols="12" md="6">
                                    <textFieldForm 
                                        @valueChange="(v) => {values.warehouse = v; delete serverErrors.warehouse}" 
                                        :valueInput="values.warehouse" 
                                        label="Solicitante"
                                        :errorMessages="serverErrors.warehouse"
                                        data-cy="warehouse-input"
                                        :disabled="true"
                                    ></textFieldForm>
                                </v-col>
                            </v-row>
                            <v-row class="py-1" align="center" justify="center">
                                <v-col class="py-1" cols="12" >
                                    <textAreaForm 
                                        @valueChange="(v) => {values.observations = v; delete serverErrors.observations}" 
                                        :valueInput="values.observations" 
                                        label="Observaciones"
                                        :errorMessages="serverErrors.observations"
                                        data-cy="observations-input"
                                        :disabled="true"
                                    ></textAreaForm>
                                </v-col>
                            </v-row>


                            <v-row justify="space-between">
                                <v-col>
                                    <div class="text-uppercase font-weight-bold black--text text-h6">
                                        Catálogo
                                    </div>
                                </v-col>
                                <v-col>
                                    <div class="text-uppercase text-right">
                                        <span  data-cy="reject-btn" @click="rejectSelection()" class="RequisitionReview-reject-btn RequisitionReview-text-btn mr-2">Rechazar selección</span>
                                        <span  data-cy="approve-btn" @click="approveSelection()" class="RequisitionReview-approve-btn RequisitionReview-text-btn">Aprobar selección</span>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-data-table 
                                    :key="tableUpdate" 
                                    v-model="tableSelected"
                                    item-key="supply_id"
                                    class="table-b-border-header" 
                                    :headers="tableProps.headers" 
                                    :items="values.supplies" 
                                    disable-sort 
                                    disable-pagination 
                                    disable-filtering 
                                    hide-default-footer
                                    :single-select="false"
                                    show-select
                                    checkbox-color="primary"
                                    > 
                                    
                                    <template v-slot:[`item.actions`]="{ item }">
                                            <v-tooltip top v-for="(action, i) of item.actions" :key="i" v-permission="action.permissions??'all'">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn :data-cy="action.dataCy" :loading="action.loading" x-small v-if="action.type === 'method'" :class="`mx-0 `" icon @click="method_handler({
                                                    methodName: action.action, 
                                                    parameters:  item.parameters
                                                })"
                                                :disabled="action.disabled">
                                                        <v-icon  :class="`${action.class}`" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                                    </v-btn>
                                                    <v-btn :data-cy="action.dataCy"  :loading="action.loading" x-small v-else-if="action.type === 'redirect'" icon text :to="action.action" :disabled="action.disabled">
                                                        <v-icon :class="`${action.class} `" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                                    </v-btn>
                                                    <v-btn :data-cy="action.dataCy" :loading="action.loading" x-small v-else-if="action.type === 'external'" icon text target="_blank" :href="action.action" :disabled="action.disabled">
                                                        <v-icon  :class="`${action.class} `"  :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ action.name }}</span>
                                            </v-tooltip>
                                        </template>

                                        <template v-slot:[`item.status`]="{ item }">
                                            <span :class="`${getStatusColor(item.status)}--text font-weight-bold`">{{item.status}}</span>
                                            <!-- <v-chip :color="getStatusColor(item.status)"><span :class="`${getStatusTextColor(item.status)}--text`">{{item.status}}</span></v-chip> -->
                                        </template> 

                                        <template v-slot:item.quantity="props" >

                                            <div class="table-item">
                                                <textFieldForm 
                                                    @valueChange="(v) => {props.item.quantity = v; delete serverErrors.products}" 
                                                    :valueInput="props.item.quantity" 
                                                    :rule="[...rules.quantity]" 
                                                    data-cy="table-quantity-input"
                                                ></textFieldForm>
                                            </div>
                                        </template>

                                        <template v-slot:item.actions="item" >

                                            <RequisitionTableActions
                                                :itemInput="item.item"
                                                :parameters="item.parameters"
                                                :edit="true"
                                                @methodHandler="method_handler"
                                                :nudgeLeft="7"
                                            ></RequisitionTableActions>

                                            <!-- <v-tooltip top v-for="(action, i) of item.item.actions" :key="i">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" :data-cy="action.dataCy" x-small class="mx-0" icon @click="method_handler({
                                                            methodName: action.action, 
                                                            parameters:  item.item.parameters
                                                            })" :disabled="action.disabled"
                                                    >
                                                        <v-icon  v-if="action.action != 'openComments'" :color="action.icon.color" >{{ action.icon.icon }}</v-icon>

                                                        <v-menu
                                                        v-if="action.action == 'openComments'"
                                                        v-model="item.item.menu"
                                                        
                                                        left
                                                        offset-x
                                                        nudge-top="10"
                                                        nudge-left="12"
                                                        :close-on-content-click="false"
                                                        content-class="menuclasss"
                                                        max-width="500"
                                                        >
                                                        <template v-slot:activator="{ on, attrs }">
                                                             
                                                                <v-btn v-bind="attrs" :data-cy="action.dataCy" x-small class="mx-0" icon  :disabled="action.disabled" >
                                                                    <v-icon :color="action.icon.color" @click="(a)=>{on.click(a); }">{{ action.icon.icon }}</v-icon>
                                                                </v-btn>
                                                                <div :class="`${item.item.menu ? 'requisitionsReview-testclass d-none d-md-inline-flex' : 'requisitionsReview-notshow'} triangle-left ` ">
                                                                </div>
                                                        
                                                        </template>

                                                        <div class="requisitionsReview-comments-container" :key="messagesWindowKey">
                                                            <p class="py-2 my-0 font-weight-bold">
                                                                Comentarios sobre el producto {{item.item.name}}
                                                            </p>
                                                            
                                                            <div class="requisitionsReview-comments-chat" ref="listChat" v-if="openComments(item)"
                                                            
                                                            >
                                                                <div v-for="(message, index) in item.item.localMessages" :key="index">
                                                                    <div :class="`my-3 mx-2 ${message.position == 'right' ? 'text-right' : 'text-left'}`">
                                                                        <p class="pb-0 mb-0 requisitionsReview-comments-comment">{{message.comments}}</p>
                                                                        <p class="pb-0 mb-0 requisitionsReview-comments-user">{{message.user}} ({{message.date}})</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <v-row>
                                                                <v-col class="py-2 mt-3">
                                                                    <textAreaForm 
                                                                        @valueChange="(v) => {comment = v; delete serverErrors.comments}" 
                                                                        :valueInput="comment" 
                                                                        :rule="[]" 
                                                                        label="Comentarios"
                                                                        :errorMessages="serverErrors.comments"
                                                                        data-cy="comments-item-input"
                                                                        class="my-3"
                                                                    ></textAreaForm>
                                                                </v-col>
                                                                <v-col class="py-0 my-0 mb-5" cols="12" md="3" align-self="end">
                                                                    <div @click="() => {addComments(item.item)}">
                                                                        <primary-button data-cy="closeComments-btn" :props="closeCommentsBtnData" class="" ></primary-button> 

                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                            
                                                        </div>
                                                    </v-menu>
                                                    
                                                    </v-btn>

                                                    
                                                </template>
                                                <span>{{ action.name }}</span>
                                            </v-tooltip>
                                        -->
                                        
                                        </template> 
                                        
                                    </v-data-table>
                                    
                                    
                                        
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>

                </v-form>
                

                <!-- images modal -->
                <modal data-cy="index-modal" :props="modalProps" @modalResponse="method_handler">
                    <!-- form -->
                    <template slot="cardBody">
                        <div>
                            <v-row>
                            
                                <v-col >
                                    <!-- <v-img
                                        :src="item"
                                        height="100"
                                        width="100"
                                    ></v-img> -->
                                    <imageSlide
                                        :mini="true"
                                        :images=" selectedItem.imagesUrls"
                                    ></imageSlide>
                                </v-col>
                            </v-row>
                            
                        </div>
                    </template>
                    <!-- Buttons -->
                    <template slot="cardActions">
                        <v-row justify="end" data-cy="index-modal-actions">
                            <!-- <v-col cols="12" sm="4">
                                <secondaryButton
                                data-cy="cancel-modal-btn"
                                :props="cancelModal"
                                ></secondaryButton>
                            </v-col> -->
                            <v-col cols="12" sm="4">
                                <primaryButton
                                data-cy="accept-modal-btn"
                                :props="acceptModal"
                                ></primaryButton>
                            </v-col>
                        </v-row>
                    </template> 
                </modal>

            

                

            </div>
           
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../../helpers/Utils';
import RequisitionTableActions from "./RequisitionTableActions.vue";
// import RequisitionForm from "./Form.vue";
export default {
    components: {
        // 'requisition-form': RequisitionForm,
        RequisitionTableActions
    },
	data(){
		return {
            id: this.$route.params.id,
            tableUpdate:1,
            loading: false,
            loadingWarehouse:false,
            serverErrors: {},
            tableSelected:[],
            selectedItem: {},
            edit:true,
            messagesWindowKey: 333,
            comment: "",
            modalProps: {
				visible: false,
				width: '700',
				title: 'Imágenes',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				
			},
			
			
			acceptModal: {
                text: "Aceptar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				textColor:'black',
                textColorLevel: '0',
				loading:false,
                cy:"accept-image-modal-btn",
                click: ()=>{this.modalProps.visible = false}
            },
            closeCommentsBtnData: {
                text: "Aceptar",
                icon: "",	
                click: ()=>{},
                block:false,
                loading: false,
            },
            values: {
                
              

                //new form
                date: "",
                requester: "",
                priority:0,
                warehouse:"",
                warehouse_id: "",
                observations: "",
                supplies: [
                    // {
                    //     id:1,
                    //     part: "1",
                    //     description: "camas de pescado hd para exteriores",
                    //     unit: "pieza",
                    //     quantity: 1,
                    //     status: "aprobada",
                    // },
                    // {
                    //     id:2,
                    //     part: "1",
                    //     description: "camas de pescado hd para exteriores dohjfsdihf lskdfh ksdjf hdlsifusydfhlisudfhl isdufh lsdifuhsdfiu",
                    //     unit: "pieza",
                    //     quantity: 1,
                    //     status: "pendiente",
                    // },
                    // {
                    //     id:3,
                    //     part: "1",
                    //     description: "camas de pescado hd para exteriores dohjfsdihf lskdfh ksdjf hdlsifusydfhlisudfhl isdufh lsdifuhsdfiu",
                    //     unit: "pieza",
                    //     quantity: 1,
                    //     status: "solicitada",
                    // },
                    //  {
                    //     id:4,
                    //     part: "1",
                    //     description: "camas de pescado hd para exteriores dohjfsdihf lskdfh ksdjf hdlsifusydfhlisudfhl isdufh lsdifuhsdfiu",
                    //     unit: "pieza",
                    //     quantity: 1,
                    //     status: "rechazada",
                    // }
                ],

                    
            },
            cancelBtnData: {
                text: "Cancelar",
                color:"transparent",
                icon: "",
                to: "Requisitions",
                block:false,
                click: () => {}
            },
            saveBtnData: {
                color: "success",
                text: "Guardar",
                icon: "",
                textColor:"white",
                textColorLevel: '0',
                click: this.save,
                block:false,
                loading: false,
            },
            serverItems:{
                warehouses:[]
            },
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
               

                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
				
				
            },
            actions: [
				{
					name: 'Aprobar',
					icon: {
						color: 'green',
						icon: 'mdi-check-circle-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'approveItem',
                    permissions: 'requisitions.delete',
                    dataCy: "approve-link"
				},
                {
					name: 'Rechazar',
					icon: {
						color: 'red',
						icon: 'mdi-close-circle-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'rejectItem',
                    permissions: 'requisitions.delete',
                    dataCy: "reject-link"
				},
                {
					name: 'Descripciones',
					icon: {
						color: 'secondary',
						icon: 'mdi-message-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'openComments',
                    dataCy: "descriptions-link"
				},
				{
					name: 'Imágenes',
					icon: {
						color: 'secondary',
						icon: 'mdi-image-multiple-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'imagesItemModal',
                    dataCy: "images-link"
				},
			],
			tableProps: {
				headers: [
                     {
						text: 'Partida',
						align: 'left break-words',
						value: 'part',
                        
						class: 'table-b-border black--text'
					},
					{
						text: 'Descripción',
						align: 'left',
						value: 'name',
						width: "50%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Unidad',
						align: 'left',
						value: 'unit',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Estatus',
						align: 'left',
						value: 'status',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cantidad',
						align: 'left',
						value: 'quantity',
						
						class: 'table-b-border black--text'
					},
                   
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '15%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
        method_handler(object){
            console.log(object, "obketo")
			this[object.methodName](object.parameters)
		},
        imagesItemModal(parameters)
		{
			console.log(parameters);
            let found = this.values.supplies.find(fItem => fItem.supply_id == parameters.id)
            if(found == undefined)
                return;
            this.selectedItem = found;
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
			}
            console.log(this.selectedID, this.selectedItem, "slected")
			this.modalProps.visible = true;
			
		},
        closeItem(item){
            item.menu = false;
        },
        approveItem(item){
            let found = this.values.supplies.findIndex((x) => x.supply_id == item.id);
            if(this.values.supplies[found].status == "Pendiente"){
                this.values.supplies[found].status = "Aprobada";
                this.values.supplies[found].actions = this.setActions(this.values.supplies[found].status);
            }
        },
        approveSelection(){
            for (let i = 0; i < this.tableSelected.length; i++) {
                this.approveItem({id: this.tableSelected[i].supply_id});                
            }
        },
        rejectItem(item){
            let found = this.values.supplies.findIndex((x) => x.supply_id == item.id);
            if(this.values.supplies[found].status == "Pendiente"){
                this.values.supplies[found].status = "Rechazada";
                this.values.supplies[found].actions = this.setActions(this.values.supplies[found].status);
            }
        },
        rejectSelection(){
            for (let i = 0; i < this.tableSelected.length; i++) {
                this.rejectItem({id: this.tableSelected[i].supply_id});
            }
        },
        addComments(item){
            if(this.comment == "")
                return;
            // console.log(item, "add cooment")
            // this.values.localMessages = 
            item.comments = JSON.parse(JSON.stringify(this.comment));
            if(this.edit){
                let edited = item.localMessages.find((x) => x.isNew == true);
                if(edited != undefined){
                    edited.comments = item.comments
                }else{
                    item.localMessages.push({
                        comments: item.comments,
                        date:  Utils.getNowDate(),
                        position: "right",
                        user: this.$store.state.loggedUsername,
                        isNew:true,
                    })
                }
               
            }else{
                item.localMessages = [
                    {
                        comments: item.comments,
                        date:  Utils.getNowDate(),
                        position: "right",
                        user: this.$store.state.loggedUsername,
                        isNew: true,
                    }
                ];
            }


            this.comment = "";
            this.messagesWindowKey = this.messagesWindowKey + 1;
            item.menu = false;
            console.log(item, "completo")
        },
        closeComments(){
            this.menu = false;
        },
        tobottom () {
            try {
                this.$refs.listChat[0].scrollTop = this.$refs.listChat[0].lastElementChild.offsetTop;
            } catch (error) {
                console.log(error);
            }
        },
        openComments(){
            try {
                setTimeout(() => {
                    this.tobottom();
                    
                }, 100);
            } catch (error) {
                console.log(error);
            }

            return true;
        },
       
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.requisition.showRevision(this.id)
			.then((response) => {
                console.log(response);
                

                this.values.date = response.data.data.date;
                this.values.warehouse_id = response.data.data.warehouse_id;
                this.values.warehouse = response.data.data.warehouse;
                this.values.observations = response.data.data.observations;
                this.values.priority =  response.data.data.priority;
                this.values.requester = response.data.data.requester;

                //supplies 
                this.values.supplies = response.data.supplies.map((x, i) => {
                    // x.status = "Aprobada"
                    x['actions'] = this.setActions(x.status)
                    x['parameters'] = { id: x.sku, name: x.sku, index: i};


                    console.log(x, "antes")
                    if(x.comments == null) {
                        x.comments = [];
                    }
                    if(x.visual_support_url == null) {
                        x.visual_support_url = [];
                    }
                    console.log(x, "desp")


                    // x['actions'] = JSON.parse(JSON.stringify(this.actions));
                    x['parameters'] = { id: x.supply_id, name: x.supply, index: i};

                    x['localMessages'] = [...x?.comments.map((ms) => {return{...ms, isNew:false}})];
                    x['imagesUrls'] = x?.visual_support_url.map((img) => {return {url:img}});
                    // x['supply_id'] = x.supply_id;
                    // x['unit'] = x.unit;
                    // x['quantity'] = x.quantity;
                    x['visual_supportBackup'] = x.visual_support;
                    x['visual_support'] = [];
                    // x['comments'] = x.comments;
                    x['name'] = x.supply;
                    x['part'] =i+1;
                    return x;
                });

             
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
      
        setActions(status){
            let actions = JSON.parse(JSON.stringify(this.actions));
            console.log(actions, "acitons taken")
            switch (status) {
                case "Aprobada":
                    
                    actions[0].disabled = true;
                    actions.splice(1, 1);
                    break;
                case "Solicitada":
                    actions.splice(1, 1);
                    actions[0].disabled = true;
                    actions[1].disabled = true;
                    actions[2].disabled = true;
                    break;

                case "Rechazada":
                    actions.splice(0, 1);
                    actions[0].disabled = true;
                    actions[1].disabled = false;
                    actions[2].disabled = false;
                    break;
            
            
                default:
                    break;
            }
            console.log(actions, "despues")
            return actions;
        },
       
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        prepareTable(){
            this.tableProps.items = this.values.products.map((x,i) => {
                x['actions'] = JSON.parse(JSON.stringify(this.actions));
                x['parameters'] = { id: x.sku, name: x.sku, index: i};


                return x
            })
        },
        
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        save(){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                let form = this.prepareRequest(this.values);
                console.log(form);
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.requisition.revisionUpdate(this.id, form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
                        this.$router.push({name: 'Requisitions'});
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("errors" in error.response.data)){
                                    if(error.response.data.errors instanceof Object){
                                        Object.keys(error.response.data.errors).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.errors;
                                            error.response.data.errors[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.errors)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario incompleto`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));
            
            for (let i = 0; i < data.supplies.length; i++) {
                delete data.supplies[i].name;
                delete data.supplies[i].actions;
                delete data.supplies[i].parameters;
                delete data.supplies[i].imagesUrls;
                delete data.supplies[i].localMessages;
                delete data.supplies[i].menu;
                delete data.supplies[i].part;
                delete data.supplies[i].visual_support;
                delete data.supplies[i].visual_support_url;
                delete data.supplies[i].visual_supportBackup;
                delete data.supplies[i].supply;

                if(Array.isArray(data.supplies[i].comments)){
                    data.supplies[i].comments = ""
                }

                if(data.supplies[i].status == "Aprobada")
                    data.supplies[i].status = "approved";
                if(data.supplies[i].status == "Pendiente")
                    data.supplies[i].status = "pending";
                if(data.supplies[i].status == "Rechazada")
                    data.supplies[i].status = "rejected";
                
            }
            console.log(JSON.stringify(data.supplies))
            return {supplies: data.supplies};
        },
        getStatusColor(status){
            switch (status) {
                case "Pendiente":
                    return "";
              
                case "Aprobada":
                case "Solicitada":
                    return "green";
                case "Rechazada":
                    return "red";
             
                    
            
                default:
                    return ""
            }
        },
        getStatusTextColor(status){
            switch (status) {
                case "parcial":
                    return "black";
                    
            
                default:
                    return "white"
            }
        },
        deleteItemModal(params){
            console.log("push", params)
        }
    },
}
</script>

<style lang="scss">
    .RequisitionReview{
        &-text-btn{
            text-decoration-line: underline;
            font-weight: bold;
            font-size: .93rem;
            cursor: pointer;
        }
        &-reject-btn{
            color: red;
            
        }
        &-approve-btn{
            color: green;
        }
    }

    .specialRed{
        input{
            color: red !important;
        }
    }

</style>